import PropTypes from 'prop-types';
import React from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';
import { getFraudBlockText } from 'react/shared/utils/FraudBlockText';
import bindAll from 'react/shared/utils/bind_all';

export default class OldDashboardCardHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      card_balance: null,
      card_status: null,
      fraudBannerText: '',
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardCardStore.on('cards.refreshedCurrentCardStatus', this.setCurrentCardStatus);
    DashboardActions.refreshCurrentCardStatus(this.props.accountId);
  }

  componentWillUnmount() {
    DashboardCardStore.off('cards.refreshedCurrentCardStatus', this.setCurrentCardStatus);
  }

  setCurrentCardStatus(accountId, cardStatus) {
    if (accountId != this.props.accountId) return;

    const fraudBannerText = getFraudBlockText(cardStatus?.fraud_block_type);
    this.setState({ ...cardStatus, fraudBannerText });
  }

  cardStatus() {
    const { card_status, card_balance } = this.state;
    switch (card_status) {
      case null:
        return '';
      case 'Active':
        return `Card Balance: ${card_balance}`;
      case 'Temporary block':
        return (
          <span data-toggle="tooltip" title={this.state.fraudBannerText}>
            Card Status: Temporary block
          </span>
        );
      default:
        return `Card Status: ${card_status}`;
    }
  }

  render() {
    return (
      <div
        className="sub_section_header"
        style={{
          marginTop: '-15px',
          paddingBottom: '50px',
          borderBottom: '1px solid #aaa',
          marginBottom: 20,
        }}
      >
        <div className="pull-left">{this.props.client.person.name}</div>
        <div className="pull-right">{this.cardStatus()}</div>
      </div>
    );
  }
}

OldDashboardCardHeader.propTypes = {
  client: PropTypes.shape({
    person: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  accountId: PropTypes.string.isRequired,
};
