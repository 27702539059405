import { useQuery } from '@tanstack/react-query';
import { fetchDisbursementsIndex } from 'react/member/components/dashboard/disbursements/filter_table/utils/disbursements_v2_api';

export default function useDisbursementIndexQuery({ clientId, filters }) {
  const result = useQuery(['disbursements', clientId, filters], () =>
    fetchDisbursementsIndex(clientId, filters),
  );

  return result;
}
