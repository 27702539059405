import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import _contains from 'underscore/modules/contains';
import _isArray from 'underscore/modules/isArray';
import _isEmpty from 'underscore/modules/isEmpty';
import _isString from 'underscore/modules/isString';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBudgetItemConfirmationPage from 'react/member/components/dashboard/budget_items/DashboardBudgetItemConfirmationPage';
import DashboardNewBudgetItem from 'react/member/components/dashboard/budget_items/DashboardNewBudgetItem';
import DashboardPaymentMethodInputs from 'react/member/components/dashboard/budget_items/DashboardPaymentMethodInputs';
import ClientDropdown from 'react/member/components/dashboard/clients/ClientDropdown';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { isBusinessDayAfterToday } from 'react/shared/utils/ValidBusinessDate';
import { extractFormData } from 'react/shared/utils/form_serialization';
import sendEvent from 'react/shared/utils/google_analytics';

export default function DashboardBudgetItemForm({
  currentClientSlug: propsCurrentClientSlug,
  organizationSlug,
  toggleBudgetItemForm: propsToggleBudgetItemForm,
  budgetItemType,
  setBudgetItemType: propsSetBudgetItemType,
  actionPath,
  initialClient,
  displayedPaymentMethods,
  payees,
  payeesLoaded,
  threeMonthInfo,
  editExternalCheckDisposition,
  resumedDraftDisbursementId,
  userRoles,
}) {
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [clients, setClients] = useState([]);
  const [currentClientSlug, setCurrentClientSlug] = useState(
    (initialClient && initialClient.slug) || propsCurrentClientSlug,
  );
  const [currentClient, setCurrentClient] = useState(initialClient || {});
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [overdrawError, setOverdrawError] = useState({});
  const [canOverride, setCanOverride] = useState(false);
  const [payee, setPayee] = useState(null);
  const [currentMerchant, setCurrentMerchant] = useState(null);
  const [categories, setCategories] = useState(null);
  const [draftSaveTrigger, setDraftSaveTrigger] = useState(false);

  const queryClient = useQueryClient();

  const onChangeClients = useCallback(() => {
    setClients(DashboardClientStore.getClients());
  }, []);

  const onFetchDraftDisbursement = useCallback(
    (disbursement) => {
      setFormData(disbursement);
      setCurrentPaymentMethod(
        disbursement.payment_method || (displayedPaymentMethods ? displayedPaymentMethods[0] : ''),
      );
      setCategories(disbursement.categories);

      if (disbursement.trust_beneficiary) {
        const clientSlug = disbursement.trust_beneficiary.slug;
        setCurrentClientSlug(clientSlug);
        DashboardActions.fetchPayees(clientSlug);
        DashboardActions.fetchClientBySlug(clientSlug);
      }
      if (disbursement.payment_method == 'Direct Debit') {
        setCurrentMerchant(disbursement.merchant);
      }
    },
    [displayedPaymentMethods],
  );

  const setUpdatedClient = useCallback((client) => {
    setCurrentClient(client);
  }, []);

  const onChangeCurrentClient = (event) => {
    const clientSlug = event.target.value;
    if (clientSlug !== 'blank') {
      setCurrentClientSlug(clientSlug);
      DashboardActions.fetchPayees(clientSlug);
      DashboardActions.fetchClientBySlug(clientSlug);
    }
  };

  const togglePayeeForm = (ev) => {
    if (typeof ev.preventDefault === 'function') {
      ev.preventDefault();
    }
    setModalOpen(!modalOpen);
  };

  const closePayeeForm = () => {
    setModalOpen(false);
  };

  const onAddBeneficiaryPayee = useCallback((payee) => {
    setPayee(payee);
    setModalOpen(false);
  }, []);

  const onFetchBeneficiaryPayees = useCallback(() => {
    const payeeId = formData.payee_id;
    if (payeeId) {
      setPayee(DashboardPayeeStore.get(Number(payeeId)));
    }
  }, [formData.payee_id]);

  const setBudgetItemType = (event) => {
    const budgetItemType = event.target.value;
    const incomplete_data = $(event.target).closest('form').serializeArray();
    const storedData = extractFormData(incomplete_data);
    setFormData(storedData);
    return propsSetBudgetItemType(budgetItemType);
  };

  const allCategoriesSelected = (categories, count) => {
    if (_isString(categories) && count === 1) {
      return true;
    }
    if (_isArray(categories) && categories.length === count) {
      return true;
    }
    return false;
  };

  const checkForRequiredFields = (data) => {
    const errors = [];

    if (!data.trust_beneficiary_id) {
      errors.push('trust beneficiary');
    }
    if (!data.card_id && data.payment_method && data.payment_method === 'Card') {
      errors.push('card');
    }
    if (
      !data.payee_id &&
      ['Check', 'External Check', 'EFT', 'Wire'].includes(data.payment_method)
    ) {
      errors.push('payee');
    }
    if (!data.payment_method) {
      errors.push('payment type');
    }
    if (!data.amounts || _contains(data.amounts, '')) {
      errors.push('amount for every category');
    }
    if (data.budget_item_type !== 'one_time' && !data.start_date) {
      errors.push('start date');
    }
    if (data.customDate && data.customDate === 'customDate') {
      if (!data.delivery_date || !isBusinessDayAfterToday(data.delivery_date, 'MM/DD/YYYY')) {
        errors.push('valid delivery date');
      }
    }

    // The category dropdowns are complicated enough that they need to be checked separately
    const categoryCount = _isArray(data.amounts) ? data.amounts.length : 1;
    if (!allCategoriesSelected(data.categories, categoryCount)) {
      errors.push('selection for every category');
    }
    return errors;
  };

  const budgetItemCreationFail = useCallback(() => {
    setDisabled(false);
  }, []);

  const submitForm = (data) => {
    setFormData(data);
    setFormSubmitted(true);
    setCanOverride(false);
    return $('html, body').animate({ scrollTop: 135 }, 'slow');
  };

  const handleOverdrawError = useCallback((data, overdrawError) => {
    if (_isEmpty(overdrawError)) {
      submitForm(data);
      return $('html, body').animate({ scrollTop: 135 }, 'slow');
    }
    if (!overdrawError.urgent) {
      setCanOverride(true);
    }
    setOverdrawError(overdrawError);
  }, []);

  const clearOverdrawError = () => {
    setOverdrawError({});
  };

  const handlePaymentMethod = (ev) => {
    setCurrentPaymentMethod(ev.target.value);
  };

  const supportedPaymentMethods = () => {
    const displayedPaymentTypes = currentClient?.trust?.displayed_payment_types;
    if (!displayedPaymentTypes) {
      return [];
    }

    return displayedPaymentTypes.filter((type) => !['Exchange', ''].includes(type));
  };

  const renderErrors = (errors) => {
    const last_error = errors.pop();
    let msg = errors.length === 0 ? last_error : `${errors.join(', ')} and ${last_error}`;
    msg = _contains(['a', 'e', 'i', 'o', 'u'], msg[0]) ? `an ${msg}` : `a ${msg}`;
    Truelink.flash('error', `Please enter ${msg}.`);
  };

  // Since recurring disbursements can only have one category, the introduction of multi-category
  // disbursements posed a data-structure problem. After looking at different options, the preferred
  // solution is to rework the data so that it matches the old expected format.
  const fixUpRecurringData = (data) => {
    if (data['budget_item_type'] === 'one_time') {
      if (resumedDraftDisbursementId) {
        data['id'] = resumedDraftDisbursementId;
      }
      return data;
    }

    // These inputs end up being an array when there are multiple, but a string when there's only one.
    // There should only be one category when we have a recurring budget. Note that the original method
    // uses singular input names as well
    data['amount'] = data['amounts'];
    data['category'] = data['categories'];
    if (data['memos']) {
      data['memo'] = data['memos'];
    }

    if (data['attachment_ids[0]']) {
      data['attachment_ids'] = data['attachment_ids[0]'];
      delete data['attachment_ids[0]'];
    }

    if (data['attachment_smartpayables_insert_ids[0]']) {
      data['attachment_smartpayables_insert_ids'] = data['attachment_smartpayables_insert_ids[0]'];
      delete data['attachment_smartpayables_insert_ids[0]'];
    }

    // The attachment insert ID is already included previously thru the attachment_smartpayables_insert_ids attr
    if (Object.hasOwn(data, 'disbursement[selected_insert]')) {
      delete data['disbursement[selected_insert]'];
    }

    return data;
  };

  const checkForDraftErrors = (data) => {
    const errors = [];
    if (!data.trust_beneficiary_id) {
      errors.push('trust beneficiary');
    }
    // The category dropdowns are complicated enough that they need to be checked separately
    const categoryCount = _isArray(data.amounts) ? data.amounts.length : 1;
    if (!allCategoriesSelected(data.categories, categoryCount)) {
      errors.push('selection for every category');
    }

    return errors;
  };

  const handleSubmit = (ev, isDraft = false) => {
    ev.preventDefault();
    const budgetItemForm = $(ev.target).closest('form');
    const serializedData = budgetItemForm.serializeArray();
    let data = extractFormData(serializedData);
    data = fixUpRecurringData(data);

    if (isDraft) {
      data.is_draft = true;

      const errors = checkForDraftErrors(data);
      if (_isEmpty(errors)) {
        setFormData(data);
        setDraftSaveTrigger(true);
      } else {
        renderErrors(errors);
      }
    } else {
      const errors = checkForRequiredFields(data);

      if (_isEmpty(errors)) {
        if (!currentClient.trust.is_pooled || canOverride || _isEmpty(threeMonthInfo)) {
          return submitForm(data);
        }
        return DashboardActions.checkOverdraw(
          data,
          threeMonthInfo,
          currentClientSlug,
          currentClient.can_overdraw,
        );
      }
      renderErrors(errors);
    }
  };

  const editBudgetItem = (_event) => {
    setFormSubmitted(false);
  };

  const toggleBudgetItemForm = () => {
    setCanOverride(false);
    propsToggleBudgetItemForm();
  };

  const packageData = useCallback(
    (data) => {
      if (budgetItemType === 'recurring') {
        return { recurring_disbursement: data };
      }
      return { disbursement: data };
    },
    [budgetItemType],
  );

  const confirmNewBudgetItem = useCallback(
    (isDraft) => {
      setDisabled(true);
      if (formData.budget_item_type === 'recurring') {
        delete formData.payee_disposition;
      }
      delete formData.budget_type;
      delete formData.budget_item_type;
      const data = packageData(formData);

      queryClient.invalidateQueries({ queryKey: ['disbursements'] });

      const url = actionPath(budgetItemType, isDraft);
      DashboardActions.createBudgetItem(data, url);
      const routeTracker = window.sessionStorage.getItem('RequestDisbursementsRouteTracking');
      sendEvent('button_click', {
        campaign: 'Request Disbursement',
        page: 'Confirm Disbursement Page',
        reason: `Disbursement confirmation clicked from ${routeTracker}`,
        component: 'DashboardBudgetItemForm',
      });
    },
    [formData, budgetItemType, actionPath, packageData, queryClient],
  );

  const confirmNewBudgetItemOnClick = (_ev) => {
    confirmNewBudgetItem(false);
  };

  const updateDraftDisbursement = useCallback(() => {
    setDisabled(true);
    const newFormData = {
      ...formData,
      budget_type: undefined,
      budget_item_type: undefined,
    };
    const data = packageData(newFormData);
    DashboardActions.updateDraftDisbursement(resumedDraftDisbursementId, data, {
      update: true,
    });
  }, [formData, resumedDraftDisbursementId, packageData]);

  const handlePayeeChange = (payee) => {
    setPayee(payee);
  };

  const renderPortfolio = () => {
    if (!(currentClient && currentClient.portfolios)) return;

    const activePortfolio = currentClient.portfolios[0];
    return (
      <div className="input-group portfolio" style={{ flex: '0 1 auto', width: '50%' }}>
        <label className="new-form__label" htmlFor="clientSlug">
          Portfolio
        </label>
        <div className="new-form__data">{activePortfolio && activePortfolio.name}</div>
      </div>
    );
  };

  useEffect(() => {
    DashboardClientStore.on('clients.fetch', onChangeClients);
    DashboardPayeeStore.on('payees.add', onAddBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', onFetchBeneficiaryPayees);
    DashboardClientStore.on('client.fetch', setUpdatedClient);
    DashboardBudgetItemStore.on('budgetItem.overdrawChecked', handleOverdrawError);
    DashboardBudgetItemStore.on('budgetItem.create.fail', budgetItemCreationFail);
    DashboardDraftDisbursementStore.on('draftDisbursement.fetch', onFetchDraftDisbursement);

    return () => {
      DashboardClientStore.off('clients.fetch', onChangeClients);
      DashboardPayeeStore.off('payees.add', onAddBeneficiaryPayee);
      DashboardPayeeStore.off('payees.fetch', onFetchBeneficiaryPayees);
      DashboardClientStore.off('client.fetch', setUpdatedClient);
      DashboardBudgetItemStore.off('budgetItem.overdrawChecked', handleOverdrawError);
      DashboardBudgetItemStore.off('budgetItem.create.fail', budgetItemCreationFail);
      DashboardDraftDisbursementStore.off('draftDisbursement.fetch', onFetchDraftDisbursement);
    };
  }, [
    onChangeClients,
    onAddBeneficiaryPayee,
    onFetchBeneficiaryPayees,
    setUpdatedClient,
    handleOverdrawError,
    budgetItemCreationFail,
    onFetchDraftDisbursement,
  ]);

  useEffect(() => {
    if (resumedDraftDisbursementId) {
      DashboardActions.fetchDraftDisbursementById(resumedDraftDisbursementId);
    }
  }, [resumedDraftDisbursementId]);

  useEffect(() => {
    DashboardActions.fetchClientsByOrganization(organizationSlug);
  }, [organizationSlug]);

  useEffect(() => {
    if (draftSaveTrigger) {
      if (resumedDraftDisbursementId) {
        updateDraftDisbursement();
      } else {
        confirmNewBudgetItem(true);
      }
      setDraftSaveTrigger(false);
    }
  }, [draftSaveTrigger, resumedDraftDisbursementId, updateDraftDisbursement, confirmNewBudgetItem]);

  // Originally, this component decided to render either a confirmation page or DashboardNewBudgetItem. What
  // would happen is that once the confirmation page was rendered *instead* of DashboardNewBudgetItem, the
  // category form inputs in DashboardNewBudgetItem (including attachments) would blank out when we tried
  // re-editing the disbursement. A workaround for this could be to reprocess all of the form data when the
  // re-editing occurs and resuscitate all of the categories. A simpler workaround is to hide
  // DashboardNewBudgetItem and allow it to retain all of the form elements it originally had. This simpler
  // workaround has been chosen.

  // Surprise! The actual <form> element is in DashboardNewBudgetItem rather than here.
  return (
    <div>
      {formSubmitted && (
        <div>
          <DashboardBudgetItemConfirmationPage
            clientSlug={currentClientSlug}
            confirmNewBudgetItem={confirmNewBudgetItemOnClick}
            disabled={disabled}
            editBudgetItem={editBudgetItem}
            formData={formData}
            formSubmitted={formSubmitted}
            organizationSlug={organizationSlug}
          />
        </div>
      )}
      <div className={formSubmitted ? 'hidden' : ''}>
        <DashboardNewBudgetItem
          budgetItemType={budgetItemType}
          categories={categories}
          clearOverdrawError={clearOverdrawError}
          closePayeeForm={closePayeeForm}
          currentClient={currentClient}
          currentClientSlug={currentClientSlug}
          currentPaymentMethod={currentPaymentMethod}
          displayedPaymentMethods={displayedPaymentMethods}
          formData={formData}
          formSubmitted={formSubmitted}
          handleSubmit={handleSubmit}
          organizationSlug={organizationSlug}
          overdrawError={overdrawError}
          payeesLoaded={payeesLoaded}
          setBudgetItemType={setBudgetItemType}
          showDashboardPayeeForm={modalOpen}
          toggleBudgetItemForm={toggleBudgetItemForm}
          userRoles={userRoles}
        >
          <div className="new-form__section" style={{ paddingBottom: 0 }}>
            <div className="flex">
              <div className="input-group" style={{ flex: '0 1 auto', width: '50%' }}>
                <label className="new-form__label" htmlFor="clientSlug">
                  Client
                </label>
                <div className="new-form__data">
                  <ClientDropdown
                    clientSlug={currentClientSlug}
                    clients={clients}
                    disabled={!!initialClient}
                    hideLabel
                    onChangeCurrentClient={onChangeCurrentClient}
                  />
                </div>
              </div>
              {renderPortfolio()}
            </div>
          </div>
          <div className="new-form__section" style={{ paddingBottom: 0 }}>
            <div>
              <div className="new-form__label">Payment Type</div>
              <PaymentMethodDropdown
                displayedPaymentMethods={supportedPaymentMethods()}
                handlePaymentMethod={handlePaymentMethod}
                hideLabel
                isEditable
                paymentMethod={currentPaymentMethod}
              />
            </div>
          </div>
          <DashboardPaymentMethodInputs
            canCreatePayee={!userRoles.viewOnly}
            checkMemoLine={formData.check_memo_line}
            client={initialClient}
            currentMerchant={currentMerchant}
            currentPaymentMethod={currentPaymentMethod}
            defaultPayee={payee}
            editExternalCheckDisposition={editExternalCheckDisposition}
            onPayeeChange={handlePayeeChange}
            payees={payees}
            payeesLoaded={payeesLoaded}
            togglePayeeForm={togglePayeeForm}
          />
        </DashboardNewBudgetItem>
      </div>
    </div>
  );
}

DashboardBudgetItemForm.propTypes = {
  currentClientSlug: PropTypes.string,
  organizationSlug: PropTypes.string.isRequired,
  toggleBudgetItemForm: PropTypes.func.isRequired,
  budgetItemType: PropTypes.string.isRequired,
  setBudgetItemType: PropTypes.func.isRequired,
  actionPath: PropTypes.func.isRequired,
  initialClient: PropTypes.object,
  displayedPaymentMethods: PropTypes.array,
  payees: PropTypes.array,
  payeesLoaded: PropTypes.bool,
  threeMonthInfo: PropTypes.object,
  editExternalCheckDisposition: PropTypes.bool,
  resumedDraftDisbursementId: PropTypes.number,
  userRoles: userRoleShape.isRequired,
};
