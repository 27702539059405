import * as Yup from 'yup';
import TlFieldValidators from 'react/shared/utils/tl_field_validators';

const asciiString = Yup.string().matches(/^[\p{ASCII}]+$/u, 'Cannot contain special characters');
const wholeNumber = Yup.string().matches(TlFieldValidators.digitsOnlyRegExp, 'Must be a number');

export default {
  accessCode: Yup.string()
    .label('4-digit access code')
    .matches(/^([0-9]{4})$/, 'Must be exactly 4 digits'),

  address: Yup.object().shape({
    street1: asciiString.label('Address Line 1').max(30, '30 characters maximum').required(),
    street2: asciiString.label('Address Line 2').max(30, '30 characters maximum'),
    city: asciiString.label('City').max(40, '40 characters maximum').required(),
    state: asciiString.label('State').required(),
    zip: Yup.string()
      .label('Zip')
      .required()
      .matches(/^\d{5}$/, 'Incorrect format for zip code'),
  }),

  asciiString,
  countryOfCitizenship: Yup.string().label('Country of Citizenship'),
  nonResidentAlien: Yup.string().label('Non-Resident Alien'),

  dateOfBirth: Yup.date()
    .label('Date of birth')
    .max(new Date(Date.now()), 'Date of birth must be in the past')
    .min(new Date('01 January 1900'), 'Date of birth must be after 1900')
    .typeError('Invalid date'),

  email: Yup.string().email().label('Email'),

  firstName: asciiString.label('First name'),

  lastName: asciiString.label('Last name'),

  mobile: Yup.string()
    .label('Phone')
    .matches(TlFieldValidators.phoneRegExp, 'Invalid format for phone number'),

  numberOfClients: wholeNumber.test({
    name: 'lessThanMax',
    exclusive: false,
    params: {},
    message: 'Must not be greater than 10,000',
    test(value) {
      return Number.parseInt(value, 10) <= 10_000;
    },
  }),

  numberOfInitialCards: wholeNumber.test({
    name: 'lessThanClients',
    exclusive: false,
    params: {},
    message: 'Must not be greater than the number of clients',
    test(value) {
      return Number.parseInt(value, 10) <= Number.parseInt(this.parent.numberOfClients, 10);
    },
  }),

  password: Yup.string()
    .label('Password')
    .min(8, '8 characters minimum')
    .matches(
      /^(?=.*[a-zA-Z])^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
      'At least one letter, number and special character',
    ),

  passwordConfirmation: Yup.string()
    .label('Re-enter password')
    .oneOf([Yup.ref('password'), null], "Passwords don't match"),

  referralCategory: Yup.string(),

  referralCategoryDetail: Yup.string(),

  referralName: Yup.string(),

  ssn: Yup.string()
    .label('SSN')
    .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'Incorrect format for Social Security Number'),

  ssnConfirmation: Yup.string()
    .label('SSN confirmation')
    .oneOf([Yup.ref('ssn'), null], "Social Security Numbers don't match"),
};
