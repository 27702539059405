import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  useRowState,
  useGlobalFilter,
  useFilters,
} from 'react-table';
import DefaultColumnFilter from './DefaultColumnFilter';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableHeaderCheckbox from './TableHeaderCheckbox';
import TableLoading from './TableLoading';
import TablePagination from './TablePagination';
import TableRowCheckbox from './TableRowCheckbox';
import TableRows from './TableRows';
import ApprovedDisbursementsFilters from 'react/member/components/dashboard/disbursements/filter_table/approved_disbursements_filters/ApprovedDisbursementsFilters';
import useUpdateEffect from 'react/shared/hooks/UseUpdateEffect';
import { black } from 'react/shared/theme/palette';

const useStyles = makeStyles({
  root: {
    color: black,
    fontFamily: '"Calibre", sans-serif',
  },
  table: {
    borderCollapse: 'collapse',
    textAlign: 'left',
    lineHeight: '1.5rem',
    width: '100%',
    fontSize: '20px',
  },
  compressed: {
    fontSize: '18px',
    '& th': {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    '& td': {
      padding: '12px',
    },
  },
  loadingIndicator: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export default function TrueLinkTable({
  className,
  columns,
  controlledFetchPage,
  controlledPageCount,
  data,
  globalFilterOverride,
  filterTitle,
  initialRowStateAccessor,
  initialSortBy,
  isFilterable = false,
  loading,
  minColumnWidth = 30,
  noDataText = 'No data available',
  onChangeRowState,
  onChangeSelected,
  pageSize = 20,
  paginated = false,
  rowProps,
  rowSubComponent,
  selectable = false,
  selectKeyField,
  showFooter = false,
  showPagination = false,
  sortable = true,
  variant = 'standard',
  withRowState = false,
}) {
  /* istanbul ignore next */
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  if (controlledFetchPage && !paginated) {
    throw 'Controlled pages must be paginated';
  }
  const classes = useStyles();

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: minColumnWidth,
    }),
    [minColumnWidth],
  );
  const tableHooks = [useFilters, useGlobalFilter, useSortBy, useExpanded];
  if (paginated) {
    tableHooks.push(usePagination);
  }
  if (selectable) {
    tableHooks.push(useRowSelect);
    tableHooks.push((hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: TableHeaderCheckbox,
          Cell: TableRowCheckbox,
        },
        ...columns,
      ]);
    });
  }
  if (withRowState) {
    tableHooks.push(useRowState);
  }

  let initialStateOptions = {};
  if (paginated) {
    initialStateOptions = {
      pageIndex: 0,
      pageSize,
    };
  }
  if (initialSortBy) {
    initialStateOptions = {
      ...initialStateOptions,
      sortBy: initialSortBy,
    };
  }

  const tableOptions = {
    columns,
    data,
    defaultColumn,
    filterTypes,
    initialRowStateAccessor: withRowState ? initialRowStateAccessor : undefined,
    initialState: initialStateOptions,
    disableSortBy: !sortable,
  };

  if (controlledPageCount) {
    tableOptions.autoResetPage = false;
    tableOptions.autoResetSortBy = false;
    tableOptions.autoResetSelectedRows = false;
    tableOptions.autoResetExpanded = false;
    tableOptions.manualPagination = true;
    tableOptions.pageCount = controlledPageCount;
  }

  if (globalFilterOverride) {
    tableOptions.globalFilter = globalFilterOverride;
  }

  const {
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    page,
    pageCount,
    rows,
    selectedFlatRows,
    prepareRow,
    state: { pageIndex, rowState, selectedRowIds, globalFilter },
    setGlobalFilter,
    setAllFilters,
  } = useTable(tableOptions, ...tableHooks);

  useEffect(() => {
    controlledFetchPage && controlledFetchPage(pageIndex, pageSize);
  }, [controlledFetchPage, pageIndex, pageSize]);

  useUpdateEffect(() => {
    if (onChangeSelected) {
      if (selectKeyField) {
        onChangeSelected(
          selectedFlatRows.reduce(
            (accumulator, current) => ({
              ...accumulator,
              [current.original[selectKeyField]]: true,
            }),
            {},
          ),
        );
      } else {
        onChangeSelected(selectedRowIds);
      }
    }
  }, [onChangeSelected, selectedFlatRows, selectedRowIds, selectKeyField]);

  useUpdateEffect(() => {
    if (withRowState) {
      onChangeRowState && onChangeRowState(rowState);
    }
  }, [onChangeRowState, rowState, withRowState]);

  const rowsToShow = page || rows;
  return (
    <div className={classNames(classes.root, { [className]: className })}>
      {isFilterable && (
        <ApprovedDisbursementsFilters
          filterTitle={filterTitle}
          globalFilter={globalFilter}
          headerGroups={headerGroups}
          setAllFilters={setAllFilters}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <table
        className={classNames(classes.table, { [classes.compressed]: variant === 'compressed' })}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <TableHeader
              headerGroup={headerGroup}
              key={idx}
              selectable={!!selectable}
              sortable={sortable}
            />
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading && <TableLoading />}
          {!loading && (
            <TableRows
              noDataText={noDataText}
              prepareRow={prepareRow}
              rowProps={rowProps}
              rowSubComponent={rowSubComponent}
              rows={rowsToShow}
              selectable={!!selectable}
            />
          )}
        </tbody>
        {showFooter && !loading && (
          <tfoot>
            {footerGroups.map((group, idx) => (
              <TableFooter footerGroup={group} key={idx} />
            ))}
          </tfoot>
        )}
      </table>
      {paginated && !!rowsToShow.length && (
        <TablePagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          previousPage={previousPage}
          rowLength={rows.length}
          showPagination={showPagination}
          totalRecords={controlledPageCount ? undefined : data.length}
        />
      )}
    </div>
  );
}

TrueLinkTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
      accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    }),
  ).isRequired,
  controlledPageCount: PropTypes.number,
  controlledFetchPage: PropTypes.func, // (page: number, pageSize: number) => undefined
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalFilterOverride: PropTypes.func,
  initialRowStateAccessor: PropTypes.func,
  noDataText: PropTypes.string,
  filterTitle: PropTypes.string,
  onChangeRowState: PropTypes.func,
  onChangeSelected: PropTypes.func,
  pageSize: PropTypes.number,
  paginated: PropTypes.oneOf([true]), // This is to prevent conditionally providing it
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'compressed']),
  sortable: PropTypes.bool,
  showFooter: PropTypes.bool,
  rowProps: PropTypes.func,
  rowSubComponent: PropTypes.func,
  selectable: PropTypes.oneOf([true]), // This is to prevent conditionally providing it
  selectKeyField: PropTypes.string,
  withRowState: PropTypes.oneOf([true]), // This is to prevent conditionally providing it
  minColumnWidth: PropTypes.number,
  isFilterable: PropTypes.bool,
  initialSortBy: PropTypes.arrayOf(PropTypes.any),
  showPagination: PropTypes.bool,
};
