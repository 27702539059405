import PropTypes from 'prop-types';
import React from 'react';
import DashboardTrustBeneficiaryProfileConnectionForm from './DashboardTrustBeneficiaryProfileConnectionForm';
import DashboardTrustBeneficiaryProfileConnectionsAttachForm from './DashboardTrustBeneficiaryProfileConnectionsAttachForm';
import DashboardTrustBeneficiaryProfileConnectionsRemoveForm from './DashboardTrustBeneficiaryProfileConnectionsRemoveForm';
import BasicTableBuilder from 'react/member/components/dashboard/misc/BasicTableBuilder';
import ConnectionStore from 'react/member/stores/ConnectionStore';
import Actions from 'react/shared/actions/actions';
import ToggleSectionHeader from 'react/shared/components/ToggleSectionHeader';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardTrustBeneficiaryProfileConnections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingConnection: false,
      attachingConnection: false,
      editingConnection: null,
      deletingConnection: null,

      connections: [],
      isLoading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    this.fetchedConnections = (connections) => this.setState({ connections, isLoading: false });

    this.createdConnection = () => this.setState({ addingConnection: false });
    this.updatedConnection = () => this.setState({ editingConnection: null });
    this.attachedConnection = () => this.setState({ attachingConnection: false });
    this.deletedConnection = () => this.setState({ deletingConnection: null });

    ConnectionStore.on(`connections.fetch.${trustBeneficiarySlug}`, this.fetchedConnections);
    ConnectionStore.on(`connections.updated.${trustBeneficiarySlug}`, this.fetchedConnections);
    ConnectionStore.on(`connections.create.${trustBeneficiarySlug}`, this.createdConnection);
    ConnectionStore.on(`connections.update.${trustBeneficiarySlug}`, this.updatedConnection);
    ConnectionStore.on(`connections.link.${trustBeneficiarySlug}`, this.attachedConnection);
    ConnectionStore.on(`connections.unlink.${trustBeneficiarySlug}`, this.deletedConnection);

    Actions.fetchConnections(this.props.trustBeneficiary.slug);
  }

  componentWillUnmount() {
    const trustBeneficiarySlug = this.props.trustBeneficiary.slug;
    ConnectionStore.off(`connections.fetch.${trustBeneficiarySlug}`, this.fetchedConnections);
    ConnectionStore.off(`connections.updated.${trustBeneficiarySlug}`, this.fetchedConnections);
    ConnectionStore.off(`connections.create.${trustBeneficiarySlug}`, this.createdConnection);
    ConnectionStore.off(`connections.update.${trustBeneficiarySlug}`, this.updatedConnection);
    ConnectionStore.off(`connections.link.${trustBeneficiarySlug}`, this.attachedConnection);
    ConnectionStore.off(`connections.unlink.${trustBeneficiarySlug}`, this.deletedConnection);
  }

  renderAddConnectionModal() {
    const { addingConnection, editingConnection } = this.state;
    if (!addingConnection && !editingConnection) return;

    const modalProps = {
      trustBeneficiarySlug: this.props.trustBeneficiary.slug,
      onClose: () => this.setState({ addingConnection: false, editingConnection: null }),
      ...(editingConnection ? { connection: editingConnection } : {}),
    };

    return <DashboardTrustBeneficiaryProfileConnectionForm {...modalProps} />;
  }

  renderAttachConnectionModal() {
    const { attachingConnection } = this.state;
    if (!attachingConnection) return;

    return (
      <TrueLinkModal
        handleClose={this.attachedConnection}
        scrollable={false}
        title={'Search for Connection'}
      >
        <DashboardTrustBeneficiaryProfileConnectionsAttachForm
          trustBeneficiary={this.props.trustBeneficiary}
        />
      </TrueLinkModal>
    );
  }

  renderDeleteConnectionModal() {
    const { deletingConnection } = this.state;
    if (!deletingConnection) return;

    return (
      <TrueLinkModal handleClose={this.deletedConnection} title={'Remove Connection'}>
        <DashboardTrustBeneficiaryProfileConnectionsRemoveForm
          connection={deletingConnection}
          trustBeneficiary={this.props.trustBeneficiary}
        />
      </TrueLinkModal>
    );
  }

  renderHeader() {
    const { canEdit, expanded, toggleExpanded } = this.props;

    return (
      <ToggleSectionHeader
        itemCount={this.state.connections.length}
        title="Connections"
        {...{ expanded, toggleExpanded }}
      >
        {canEdit ? (
          <div className="action-item">
            <button
              className="edit-link btn btn-link"
              onClick={() => this.setState({ addingConnection: true })}
              type="button"
            >
              <i className="fa fa-plus-circle" />
              <span className="padding-5l">Add Connection</span>
            </button>
          </div>
        ) : null}
        {canEdit ? (
          <div className="action-item">
            <button
              className="edit-link btn btn-link"
              onClick={() => this.setState({ attachingConnection: true })}
              type="button"
            >
              <i className="fa fa-link" />
              <span className="padding-5l">Attach Connection</span>
            </button>
          </div>
        ) : null}
      </ToggleSectionHeader>
    );
  }

  renderContentTable() {
    const { truncateWithEllipses, formatPhoneNumber } = tlFieldTransformers;
    const tableFieldConfig = {
      name: {
        title: 'Name',
        value: (connection) => (
          <p>{[connection.person.firstName, connection.person.lastName].join(' ')}</p>
        ),
      },
      relationship: {
        title: 'Relationship',
        value: (connection) => (
          <p>
            {[
              connection.connectionRelationship.professional,
              connection.connectionRelationship.social,
            ]
              .filter(Boolean) // Removes falsey values (including empty strings)
              .join(', ')}
          </p>
        ),
      },
      email: {
        title: 'Email',
        thAttributes: { style: { width: '200px' } },
        value: (connection) => (
          <p>{connection.email ? truncateWithEllipses(connection.email, 25) : ''}</p>
        ),
      },
      phone: {
        title: 'Phone',
        value: (connection) => <p>{formatPhoneNumber(connection.person.mobile)}</p>,
      },
      companyName: {
        title: 'Company',
        value: (connection) => <p>{connection.companyName}</p>,
      },
      address: {
        title: 'Address',
        value: (connection) => {
          const street2 = <div>{connection.address.street2}</div>;
          const city = connection.address.city ? `${connection.address.city},` : '';
          return (
            <div>
              <div>{connection.address.street1}</div>
              {connection.address.street2 && street2}
              <div>
                {city} {connection.address.state} {connection.address.zip}
              </div>
            </div>
          );
        },
      },
      actionItems: {
        title: '',
        thAttributes: { style: { width: '60px' } },
        value: (connection) => {
          if (!this.props.canEdit) return null;
          return (
            <div className="action-items">
              <button
                className="action-item btn btn-link edit-connection"
                onClick={() => this.setState({ editingConnection: connection })}
                type="button"
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="action-item btn btn-link danger delete-connection"
                onClick={() => this.setState({ deletingConnection: connection })}
                type="button"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    };

    return (
      <div className="content-table">
        <BasicTableBuilder
          columnList={this.columnHeadersList()}
          itemTypeKey={'connections'}
          tableFieldConfig={tableFieldConfig}
          tableItems={this.state.connections}
        />
      </div>
    );
  }

  columnHeadersList() {
    const list = [
      'name',
      'relationship',
      'companyName',
      'email',
      'phone',
      'address',
      'actionItems',
    ];

    return list;
  }

  renderContentNoItemsMessage() {
    if (this.state.connections.length > 0) return null;
    return (
      <div className="default-content-message">
        <p>No connections attached to this beneficiary.</p>
      </div>
    );
  }

  renderContent() {
    if (!this.props.expanded) return;
    return (
      <div className="toggle-section-content full-width">
        {this.renderContentTable()}
        {this.renderContentNoItemsMessage()}
      </div>
    );
  }

  render() {
    return (
      <div className="toggle-section connections">
        {this.renderAddConnectionModal()}
        {this.renderAttachConnectionModal()}
        {this.renderDeleteConnectionModal()}
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

DashboardTrustBeneficiaryProfileConnections.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  trustBeneficiary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
