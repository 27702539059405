import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Frozen({
  cardBalance,
  cardBreached,
  cardExpiryDate,
  cardLast4,
  requiresSupport,
  status,
}) {
  const selfServeNote =
    "We've frozen this account due to suspicious activity. Please review recent transactions. If all transactions are authorized you can unfreeze the Visa card by clicking the Reactivate button. If any transactions are unfamiliar or fraudulent, order a replacement card and reach out to customer support immediately to open a dispute claim.";
  const requiresSupportNote =
    "We've frozen this account due to suspicious activity. Please order a replacement Visa card and review recent transactions. If any successful transactions are unfamiliar or fraudulent, please contact us immediately to open a dispute claim.";
  const note = requiresSupport ? requiresSupportNote : selfServeNote;
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      fraudType={'FROZEN'}
      note={note}
      status={status}
    />
  );
}

Frozen.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  requiresSupport: PropTypes.bool,
  status: PropTypes.oneOf([CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS]).isRequired,
};
