import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';

export default {
  getCardStatusDetails(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_card_status_details_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  getPendingBackupCards(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_pending_backup_cards_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  getBackupCards(accountSlug) {
    return axios.get(
      RailsRoutes.dashboard_additional_cards_path(accountSlug, { format: 'json' }),
      AXIOS_XHR_CONFIG,
    );
  },
  postDisconnectClientCard(accountSlug) {
    return axios.post(
      RailsRoutes.disconnect_card_dashboard_cards_path({ format: 'json' }),
      { account_id: accountSlug },
      AXIOS_XHR_CONFIG,
    );
  },
  postDeactivateCard(accountSlug) {
    return axios.post(
      RailsRoutes.deactivate_card_dashboard_cards_path({ format: 'json' }),
      { account_id: accountSlug },
      AXIOS_XHR_CONFIG,
    );
  },
  postReactivateCard(accountSlug) {
    return axios.post(
      RailsRoutes.reactivate_card_dashboard_cards_path({ format: 'json' }),
      { account_id: accountSlug },
      AXIOS_XHR_CONFIG,
    );
  },
  postUnblockCard(accountSlug) {
    return axios.post(
      RailsRoutes.unblock_card_dashboard_cards_path({ format: 'json' }),
      { account_id: accountSlug },
      AXIOS_XHR_CONFIG,
    );
  },
};
