import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Blocked({ cardBalance, cardBreached, cardExpiryDate, cardLast4, status }) {
  const note =
    'To protect this account, we’ve temporarily set the Visa card to inactive. This may be due to several failed PIN transactions or many transactions with the same merchant in a short period. Please review recent transactions. You can reactivate the card if all transactions were authorized or order a replacement card below.';
  return (
    <CardDetails
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      expiryDate={cardExpiryDate}
      note={note}
      status={status}
    />
  );
}

Blocked.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS]).isRequired,
};
