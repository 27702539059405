import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DatePickerField from 'react/shared/components/forms/DatePickerField';

const useStyles = makeStyles({
  dateRange: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
  spacer: {
    margin: '0 10px',
  },
});

export default function ReportDateRange(props) {
  const classes = useStyles();
  const trueLinkBirthday = '2013-01-01';
  const maxStartDate = props.endDate || '-1';
  const minEndDate = props.startDate || trueLinkBirthday;

  return (
    <div className={classes.dateRange}>
      <span>
        <label className="hidden" htmlFor={`${props.reportName}_start_date`}>
          Start date
        </label>
        <DatePickerField
          handleDateSelect={(date) => props.onSetStartDate(date)}
          inputProps={{
            id: `${props.reportName}_start_date`,
            name: `${props.reportName}_start_date`,
            defaultValue: props.startDate,
            placeholder: 'Start date',
          }}
          key={maxStartDate}
          maxDate={maxStartDate}
          minDate={trueLinkBirthday}
        />
      </span>
      <span className={classes.spacer}>—</span>
      <span>
        <label className="hidden" htmlFor={`${props.reportName}_end_date`}>
          End date
        </label>
        <DatePickerField
          handleDateSelect={(date) => props.onSetEndDate(date)}
          inputProps={{
            id: `${props.reportName}_end_date`,
            name: `${props.reportName}_end_date`,
            defaultValue: props.endDate,
            placeholder: 'End date',
          }}
          key={minEndDate}
          maxDate="-1"
          minDate={minEndDate}
        />
      </span>
    </div>
  );
}

ReportDateRange.propTypes = {
  reportName: PropTypes.oneOf([
    'transaction_report',
    'blocked_charges_report',
    'detailed_transaction_report',
    'change_log',
  ]).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onSetStartDate: PropTypes.func.isRequired,
  onSetEndDate: PropTypes.func.isRequired,
};
