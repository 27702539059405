import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import OverdueAdditionalCardOrder from './additional_card/Overdue';
import PreReleaseAdditionalCardOrder from './additional_card/PreRelease';
import ReleasedAdditionalCardOrder from './additional_card/Released';
import Blocked from './card/Blocked';
import ClosedCard from './card/Closed';
import Frozen from './card/Frozen';
import InactiveCard from './card/Inactive';
import LostCard from './card/Lost';
import OpenCard from './card/Open';
import ReplacedCard from './card/Replaced';
import OverdueFirstCardOrder from './first_card/Overdue';
import PreReleaseFirstCardOrder from './first_card/PreRelease';
import ReleasedFirstCardOrder from './first_card/Released';
import OverdueLegacyCardOrder from './legacy_card/Overdue';
import ReleasedLegacyCardOrder from './legacy_card/Released';
import OverdueReplacementCardOrder from './replacement_card/Overdue';
import ReleasedReplacementCardOrder from './replacement_card/Released';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function CurrentCardDisplay({
  account,
  card,
  cardBalance,
  cardholder,
  onCardReissueSuccess,
  organization,
  showExpiringCardBox,
  status,
}) {
  const supportPhone = I18n.t('corp.ad_tollfree_numeric', { locale: account.program_type });
  const frozenFraudBlockTypes = [
    'transaction_risk_support_required',
    'transaction_risk_self_serve',
  ];
  switch (status) {
    case CARD_STATUSES.CLOSED_CARD_STATUS:
      return (
        <ClosedCard
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          hasOpenCardCloseRequest={card.has_open_card_close_request}
          status={status}
        />
      );
    case CARD_STATUSES.INACTIVE_CARD_STATUS:
      return (
        <InactiveCard
          account={account}
          card={card}
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          cardholder={cardholder}
          onCardReissueSuccess={onCardReissueSuccess}
          organization={organization}
          showExpiringCardBox={showExpiringCardBox}
          status={status}
        />
      );
    case CARD_STATUSES.LOST_CARD_STATUS:
      return (
        <LostCard
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          contactEmail={account.contact_email}
          status={status}
        />
      );
    case CARD_STATUSES.OPEN_CARD_STATUS:
      return (
        <OpenCard
          account={account}
          card={card}
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          cardholder={cardholder}
          onCardReissueSuccess={onCardReissueSuccess}
          organization={organization}
          showExpiringCardBox={showExpiringCardBox}
          status={status}
        />
      );
    case CARD_STATUSES.OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS:
      return (
        <OverdueFirstCardOrder
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS:
      return (
        <OverdueAdditionalCardOrder
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS:
      return (
        <OverdueLegacyCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          cardholderName={cardholder.name}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          expedited={card.card_order.expediteShipping}
          expiryDate={card.expiry_date}
          oldCardLast4={card.card_last_4_deprecating}
          programType={account.program_type}
          shippingAddress={account.shipping_address_as_string}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS:
      return (
        <OverdueReplacementCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          expedited={card.card_order.expediteShipping}
          expiryDate={card.expiry_date}
          oldCardLast4={card.card_last_4_deprecating}
          shippingAddress={account.shipping_address_as_string}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS:
      return (
        <OverdueFirstCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          shippingAddress={account.shipping_address_as_string}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS:
      return (
        <OverdueAdditionalCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          shippingAddress={account.shipping_address_as_string}
          status={status}
          supportPhone={supportPhone}
        />
      );
    case CARD_STATUSES.PRE_RELEASE_FIRST_CARD_ORDER_STATUS:
      return <PreReleaseFirstCardOrder contactEmail={account.contact_email} status={status} />;
    case CARD_STATUSES.PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS:
      return <PreReleaseAdditionalCardOrder programType={account.program_type} status={status} />;
    case CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS:
      return (
        <ReleasedLegacyCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          cardholderName={cardholder.name}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          expedited={card.card_order.expediteShipping}
          expiryDate={card.expiry_date}
          oldCardLast4={card.card_last_4_deprecating}
          programType={account.program_type}
          shippingAddress={account.shipping_address_as_string}
          status={status}
        />
      );
    case CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS:
      return (
        <ReleasedReplacementCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          expedited={card.card_order.expediteShipping}
          expiryDate={card.expiry_date}
          oldCardLast4={card.card_last_4_deprecating}
          shippingAddress={account.shipping_address_as_string}
          status={status}
        />
      );
    case CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS:
      return (
        <ReleasedFirstCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          shippingAddress={account.shipping_address_as_string}
          status={status}
        />
      );
    case CARD_STATUSES.RELEASED_ADDITIONAL_CARD_ORDER_STATUS:
      return (
        <ReleasedAdditionalCardOrder
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardFboName={account.fbo_person_name}
          cardLast4={card.card_last_4}
          cardName={account.card_name}
          cardShipmentTrackingNumber={card.shipment_tracking_number}
          estimatedArrivalDate={account.estimated_arrival.arrival_date}
          estimatedArrivalRange={account.estimated_arrival.range}
          shippingAddress={account.shipping_address_as_string}
          status={status}
        />
      );
    case CARD_STATUSES.REPLACED_CARD_STATUS:
      return (
        <ReplacedCard
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          cardholderName={cardholder.name}
          programType={account.program_type}
          status={status}
        />
      );
    case CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS:
      if (frozenFraudBlockTypes.includes(card.fraud_block_type)) {
        return (
          <Frozen
            cardBalance={cardBalance}
            cardBreached={card.is_breached}
            cardExpiryDate={card.expiry_date}
            cardLast4={card.card_last_4}
            cardholderName={cardholder.name}
            requiresSupport={card.fraud_block_type === 'transaction_risk_support_required'}
            status={status}
          />
        );
      }
      return (
        <Blocked
          cardBalance={cardBalance}
          cardBreached={card.is_breached}
          cardExpiryDate={card.expiry_date}
          cardLast4={card.card_last_4}
          cardholderName={cardholder.name}
          status={status}
        />
      );
    default:
      return null;
  }
}

CurrentCardDisplay.propTypes = {
  account: accountShape.isRequired,
  card: cardShape,
  cardBalance: PropTypes.number,
  cardholder: cardholderShape.isRequired,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organization: organizationShape.isRequired,
  showExpiringCardBox: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(CARD_STATUSES)),
};
