import PropTypes from 'prop-types';
import React from 'react';
import AccountListActivation from './AccountListActivation';
import CheckBoxWithLabel from './CheckBoxWithLabel';
import Promotions from 'react/member/components/referrals/Promotions';
import Datatable from 'react/shared/components/Datatable';
import { getFraudBlockText } from 'react/shared/utils/FraudBlockText';

export default function AccountListTable(props) {
  const recordsPerPage = 25;

  const indexRoute = () => RailsRoutes.accounts_list_path({ format: 'json' });

  const columns = () => [
    <th className="js-datatable-col-nickname" key="datatable-nickname">
      Account ID
    </th>,
    <th className="js-datatable-col-first-name" key="datatable-first-name">
      First name
    </th>,
    <th className="js-datatable-col-last-name" key="datatable-last-name">
      Last name
    </th>,
    <th className="js-datatable-col-status" key="datatable-status">
      Status
    </th>,
    <th className="js-datatable-col-statement" key="datatable-statement">
      Statement
    </th>,
    <th className="js-datatable-col-latest-transfer" key="datatable-latest-transfer">
      Latest funds transfer
    </th>,
    <td className="js-datatable-col-dashboard-link" key="datatable-dashboard-link" />,
  ];

  const columnDefs = () => [
    {
      targets: ['js-datatable-col-nickname'],
      sortable: true,
      width: '14%',
      visible: props.showAccountId,
      data: 'nickname',
    },
    {
      targets: ['js-datatable-col-first-name'],
      sortable: true,
      width: '13%',
      data: 'first_name',
      //Prevents XSS attack
      render: $.fn.dataTable.render.text(),
    },
    {
      targets: ['js-datatable-col-last-name'],
      sortable: true,
      width: '13%',
      data: 'last_name',
      //Prevents XSS attack
      render: $.fn.dataTable.render.text(),
    },
    {
      targets: ['js-datatable-col-status'],
      sortable: true,
      width: '17%',
      data: 'status',
      render: (data, type, record) => {
        switch (data) {
          case 'Fraud Block': {
            const titleText = getFraudBlockText(record.fraud_block_type);
            return `${data} <i class='ico-blocked-small' title="${titleText}" data-toggle='tooltip'></i>`;
          }
          case 'COMPROMISED_ACTION_NEEDED': {
            return "<span class='compromised-card' title=\"This card has been reported as compromised. Immediate action is required. Please click into the cardholder's dashboard for more information.\" data-toggle='tooltip'>COMPROMISED<i class='ico-flagged-small'></i></span>";
          }
          case 'COMPROMISED': {
            return `<span class='compromised-card' title="This card has been reported as compromised. The card is currently inactive and immediate action is required. Please click into the cardholder's dashboard for more information." data-toggle='tooltip'>${data}<i class='ico-flagged-small'></i></span>`;
          }
          default: {
            if (data.includes('$')) {
              const { card_id } = record;
              let refreshButton = '';
              if (props.showRefreshButton) {
                refreshButton = `
              <a id='card-refresh-${card_id}' class='account-list-refresh' data-card-id='${card_id}' data-refresh-path=${RailsRoutes.card_status_pull_path(
                card_id,
              )} />
                  <i class='refresh-icon'></i>
              </a>
            `;
              }
              return `<span id='card-bal-${card_id}'>${data}</span> ${refreshButton}`;
            } else if (data === 'Activate') {
              return `
            <a href=''
               class='account_list_activation'
               data-account-id='${record.slug}'
               data-bulk-card-order='${record.bulk_card_ordered}'
               data-card_order_type='${record.card_order_type}'
               data-cardholder-first-name='${record.first_name}'
               data-cardholder-last-name='${record.last_name}'
               data-cardholder-nickname='${record.nickname}'
               data-cardholder-address='${record.address}'
               data-cardholder-mobile='${record.mobile}'
               data-program-type='${record.program_type}'
            >
              Activate
            </a>
          `;
            }
          }
        }
        return data;
      },
    },
    {
      targets: ['js-datatable-col-statement'],
      sortable: false,
      width: '17%',
      data: 'latest_statement_date',
      render: (data) => {
        if (data) {
          if (data.month) {
            return `<a href='${RailsRoutes.account_official_statement_path(
              data.account_id,
              data.month,
              { format: 'zip' },
            )}'>${data.month}</a>`;
          }
          return 'None';
        }
      },
    },
    {
      targets: ['js-datatable-col-latest-transfer'],
      sortable: false,
      width: '26%',
      visible: props.showLatestTransfer,
      data: 'transfer',
      render: (data) => {
        if (data) {
          let info = '';
          if (!data.landed) {
            info = `<i class='info-circle' data-toggle='tooltip' data-placement='top' data-original-title='
                ${data.funding_type_description}
              '></i>`;
          }
          return `${data.amount} on ${data.date} ${info}`;
        }
      },
    },
    {
      targets: ['js-datatable-col-dashboard-link'],
      sortable: false,
      width: 'auto',
      data: 'slug',
      render: (data) =>
        `<a href='${RailsRoutes.select_account_path(
          data,
        )}' class='dashboard-link'>Dashboard<i class='angle-right'></a>`,
    },
  ];

  const serverParams = () => ({ hideClosedCards: $('.hide-closed-cards').is(':checked') });

  const handleRender = (table) => <div id="account_list">{table}</div>;

  const setBalanceRefreshListeners = () => {
    $('a.account-list-refresh').on('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      $(event.target).hide();
      const $a = $(event.target).closest('a');
      const { cardId } = $a.data();
      const $oldBal = $(`#card-bal-${cardId}`);
      const url = $a.data().refreshPath;
      // hide the old balance
      $oldBal.text('Requesting..');
      // get the new
      $.ajax({ url }).then((data) => {
        $(`#card-bal-${data['card_id']}`).hide();
        $(`#card-bal-${data['card_id']}`).text(data['card_balance']);
        $(`#card-bal-${data['card_id']}`).fadeIn(1400);
      });
    });
  };

  const afterDraw = (settings) => {
    $('i.info-circle[data-toggle="tooltip"]').tooltip();

    // hide pagination if only one page
    if (settings.fnRecordsTotal() <= recordsPerPage) {
      $('.dataTables_paginate').hide();
    } else {
      $('.dataTables_paginate').show();
    }
    $('[data-toggle="tooltip"]').tooltip();

    // move the search box
    $('#account_list .dataTables_filter > label > input').appendTo('#true_account_list_filter');
    $('#account_list .dataTables_filter').closest('.row-fluid').remove();

    $('#account_list tbody tr').on('click', (e) => {
      if (e.target instanceof window.HTMLAnchorElement) {
        return;
      }
      const url = $(e.delegateTarget).find('a.dashboard-link').attr('href');

      if (e.metaKey) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    });

    const api = new $.fn.DataTable.Api(settings);
    $('.hide-closed-cards').on('change', () => api.ajax.reload());

    setBalanceRefreshListeners();
  };

  const { accountListMessage } = props;

  return (
    <div>
      <Promotions />
      {accountListMessage && (
        <div className="info-box" style={{ marginBottom: 15 }}>
          {accountListMessage}
        </div>
      )}
      <div className="clearfix" style={{ borderBottom: '1px solid #ddd', paddingBottom: 15 }}>
        <div className="form-inline" id="true_account_list_filter" style={{ float: 'left' }}>
          <span>
            <label htmlFor="account_list_search">Search:</label>
          </span>
        </div>
        <div className="hide-closed">
          <CheckBoxWithLabel
            checked
            className="hide-closed-cards"
            name="hide_closed"
            text="Hide closed cards"
          />
        </div>
      </div>
      <Datatable
        afterDraw={afterDraw}
        columnDefs={columnDefs()}
        columns={columns()}
        handleRender={handleRender}
        pageLength={recordsPerPage}
        route={indexRoute()}
        serverParams={serverParams}
      />
      <AccountListActivation />
    </div>
  );
}

AccountListTable.propTypes = {
  showAccountId: PropTypes.bool.isRequired,
  showLatestTransfer: PropTypes.bool.isRequired,
  showRefreshButton: PropTypes.bool.isRequired,
  accountListMessage: PropTypes.string,
};
