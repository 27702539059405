import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  inputDiv: {
    // This is written to target the div plus the input because other CSS was overriding
    // this when targeting the input directly
    '& input': {
      border: 'none',
      padding: theme.spacing(2),
      margin: 0,
      height: 'auto',
      '&::placeholder': {
        fontStyle: 'normal',
      },
    },
  },
}));

export default function AmountFilter({ column: { setFilter, filterValue = [] } }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [minVal, setMinVal] = useState(filterValue[0] || '');
  const [maxVal, setMaxVal] = useState(filterValue[1] || '');
  const showAmountChips = filterValue[0] !== undefined || filterValue[1] !== undefined;
  useEffect(() => {
    if (filterValue[0] === undefined) {
      setMinVal('');
    }
    if (filterValue[1] === undefined) {
      setMaxVal('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const containerRef = useRef(null);
  const handleMinChange = (e) => {
    const value = e.target.value;
    setMinVal(value);
  };

  const handleMaxChange = (e) => {
    const value = e.target.value;
    setMaxVal(value);
  };

  const handleOpenPopper = () => {
    setOpen((prev) => !prev);
    setPlacement('bottom');
  };

  const handleAmountApply = () => {
    setFilter((old = []) => [minVal ? Number.parseInt(minVal, 10) : undefined, old[1]]);
    setFilter((old = []) => [old[0], maxVal ? Number.parseInt(maxVal, 10) : undefined]);
    setOpen(false);
  };

  const handleClearAmount = () => {
    setFilter();
    setMinVal('');
    setMaxVal('');
  };

  const handleMinDelete = () => {
    setMinVal('');
    setFilter((old = []) => [undefined, old[1]]);
  };

  const handleMaxDelete = () => {
    setMaxVal('');
    setFilter((old = []) => [old[0], undefined]);
  };

  const handleClickAway = () => {
    handleClearAmount();
    setOpen(false);
  };
  return (
    <>
      <div id="amount-range-popper-container" ref={containerRef}>
        {showAmountChips && !open ? (
          <Paper
            component={'ul'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyle: 'none',
              margin: '9px 10px 10px 0',
              alignItems: 'center',
            }}
          >
            <ListItem
              style={{
                paddingLeft: '0',
                paddingRight: '0',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              {filterValue?.[0] >= 0 && (
                <TrueLinkChip
                  color="primary"
                  label={`Min ${asMoney(filterValue[0])}`}
                  onDelete={handleMinDelete}
                  style={{ color: PALETTE.emerald, fontWeight: 100 }}
                />
              )}
            </ListItem>
            <ListItem
              style={{ padding: '5px', paddingLeft: 0, marginLeft: '-15px', marginRight: '-15px' }}
            >
              {filterValue?.[1] >= 0 && (
                <TrueLinkChip
                  color="primary"
                  label={`Max ${asMoney(filterValue[1])}`}
                  onDelete={handleMaxDelete}
                  style={{ color: PALETTE.emerald, fontWeight: 100 }}
                />
              )}
            </ListItem>
            <TrueLinkButton
              onClick={handleOpenPopper}
              size="small"
              style={{ border: 'none', margin: 'auto 0 auto 15px', padding: '7px' }}
              variant="neutralOutline"
            >
              <TrueLinkIcon icon="caret-down" />
            </TrueLinkButton>
          </Paper>
        ) : (
          <TrueLinkButton
            onClick={handleOpenPopper}
            size="small"
            style={{
              height: '42px',
              marginLeft: '5px',
              marginRight: '10px',
              padding: '8px',
            }}
            variant="neutralOutline"
          >
            Amount
            <TrueLinkIcon icon="caret-down" style={{ marginLeft: '5px' }} />
          </TrueLinkButton>
        )}
      </div>
      <Popper
        anchorEl={containerRef.current}
        open={open}
        placement={placement}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              width: 'auto',
              height: '150px',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <AttachMoneyIcon
                      fontSize="small"
                      style={{ fill: PALETTE.grey2, marginLeft: '-10px' }}
                    />
                  ),
                }}
                className={classes.inputDiv}
                onChange={handleMinChange}
                placeholder="Min"
                style={{ width: '120px' }}
                value={filterValue[0] || minVal}
              />
              <Typography style={{ margin: '0 5px' }}>to</Typography>
              <TextField
                InputProps={{
                  startAdornment: (
                    <AttachMoneyIcon
                      fontSize="small"
                      style={{ fill: PALETTE.grey2, marginLeft: '-10px' }}
                    />
                  ),
                }}
                className={classes.inputDiv}
                onChange={handleMaxChange}
                placeholder="Max"
                style={{ width: '120px' }}
                value={filterValue[1] || maxVal}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '25px 10px 10px 10px',
              }}
            >
              <TrueLinkButton onClick={handleAmountApply} size="small" variant="primary">
                Apply
              </TrueLinkButton>
              <TrueLinkLink onClick={handleClearAmount} size="small" variant="error">
                Clear
              </TrueLinkLink>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

AmountFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.array,
    preFilteredRows: PropTypes.array,
    setFilter: PropTypes.func,
    id: PropTypes.string,
  }),
};
