import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';
import { getFraudBlockText } from 'react/shared/utils/FraudBlockText';

export default function DashboardAccountHeader({ accountId, firstName, lastFour }) {
  const [card, setCard] = useState({ card_balance: null, card_status: null });
  const [fraudBannerText, setFraudBannerText] = useState('');

  useEffect(() => {
    function setCurrentCardStatus(newAccountId, currentCardStatus) {
      if (accountId != newAccountId) return;
      setCard((card) => ({ ...card, ...currentCardStatus }));
      setFraudBannerText(getFraudBlockText(currentCardStatus.fraud_block_type));
    }
    DashboardCardStore.on('cards.refreshedCurrentCardStatus', setCurrentCardStatus);
    DashboardActions.refreshCurrentCardStatus(accountId);
    return () => {
      DashboardCardStore.off('cards.refreshedCurrentCardStatus', setCurrentCardStatus);
    };
  }, [accountId]);

  function renderCardStatus() {
    switch (card.card_status) {
      case null:
        return '';
      case 'Active':
        return `Card Balance: ${card.card_balance}`;
      case 'Temporary block':
        return (
          <span data-toggle="tooltip" title={fraudBannerText}>
            Card Status: Temporary block
          </span>
        );
      default:
        return `Card Status: ${card.card_status}`;
    }
  }

  return (
    <Grid container spacing={2} style={{ marginBottom: '40px' }}>
      <Grid
        item
        style={{
          fontSize: '30px',
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'left',
        }}
        xs={6}
      >
        {firstName}'s True Link Visa Card
      </Grid>
      <Grid
        item
        style={{
          fontSize: '30px',
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'right',
        }}
        xs={6}
      >
        {renderCardStatus()}
      </Grid>
      <Grid
        item
        style={{
          fontSize: '18px',
          fontWeight: 400,
          color: '#4F4F4F',
          display: 'flex',
          justifyContent: 'left',
        }}
        xs={12}
      >
        Ending in ...{lastFour}
      </Grid>
    </Grid>
  );
}

DashboardAccountHeader.propTypes = {
  firstName: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  lastFour: PropTypes.string,
};
