/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import useDisbursementShowQuery from 'react/member/components/dashboard/clients/hooks/useDisbursementShowQuery';
import useTrustBeneficiaryShowQuery from 'react/member/components/dashboard/clients/hooks/useTrustBeneficiaryShowQuery';
import DisbursementDeleteConfirmationModal from 'react/member/components/dashboard/disbursements/DisbursementDeleteConfirmationModal';
import PendingDisbursementDetailActions from 'react/member/components/dashboard/disbursements/disbursement_details/PendingDisbursementDetailActions';
import DisbursementDecisions from 'react/member/components/dashboard/disbursements/filter_table/disbursement_decisions/DisbursementDecisions';
import DisbursementCategoryAmounts from 'react/member/components/dashboard/disbursements/filter_table/disbursements_category_amounts/DisbursementCategoryAmounts';
import { grey4 } from 'react/shared/theme/palette';

const mapPaymentMethod = [
  { label: 'True Link Card', id: 'Card' },
  { label: 'Electronic Funds Transfer', id: 'EFT' },
  { label: 'Check (True Link Fulfills)', id: 'Check' },
  { label: 'Check (Generate PDF)', id: 'External Check' },
  {
    label: 'Direct Debit',
    id: 'Direct Debit',
  },
  {
    label: 'Wire',
    id: 'Wire',
  },
  {
    label: 'Exchange',
    id: 'Exchange',
  },
];

const useStyles = makeStyles(() => ({
  newFormField: {
    minHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  newFormLabel: {
    width: '200px',
    lineHeight: '1.2em',
    paddingTop: '9px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  newFormData: {
    width: 'calc(100% - 200px)',
    fontSize: '18px',
    lineHeight: '1.2em',
  },
}));

export default function PendingDisbursementsDetailView({
  disbursement,
  renderOnHoldDisbursements,
  showSendDisbursementBackToPending,
}) {
  const disbursementId = `${disbursement.id}`;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const clientId = disbursement.trust_beneficiary?.organization.slug;
  const classes = useStyles();

  const { data: { data, disbursementCategoryAmounts, decisions } = {}, isLoading } =
    useDisbursementShowQuery({
      disbursementId,
    });

  const { data: trustBeneData } = useTrustBeneficiaryShowQuery({
    clientSlug: disbursement.trust_beneficiary?.slug,
  });

  const paymentMethodLabel = mapPaymentMethod.find(
    (method) => method.id === disbursement.payment_method,
  )?.label;

  const canPrintDisbursement = disbursement.status !== 'Draft';
  const canDeleteDisbursement = data?.meta.canBeDeleted;

  const canRemoveHold = (status) =>
    status === 'Pending on Hold' ||
    status === 'Proposed on Hold' ||
    status === 'Additional Approval on Hold';

  const canGoOnHold = (status) =>
    status === 'Pending' || status === 'Proposed' || status === 'Additional Approval';

  const removeHoldAttributes = (disbursement) => {
    let attrs;
    switch (disbursement.status) {
      case 'Pending on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Pending' }];

        break;
      }
      case 'Additional Approval on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Additional Approval' }];

        break;
      }
      case 'Proposed on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Proposed' }];

        break;
      }
      // No default
    }
    return attrs;
  };

  const sendDisbursementBackToPending = () => {
    DashboardActions.returnDisbursementForRevision(disbursement.id);
  };

  const removeHold = () => {
    if (canRemoveHold(disbursement.status)) {
      const payload = removeHoldAttributes(disbursement);
      DashboardActions.updateDisbursement(disbursement.id, payload);
    }
  };

  const putOnHold = () => {
    if (canGoOnHold(disbursement.status)) {
      const payload = [{ name: 'disbursement[status]', value: `${disbursement.status} on Hold` }];
      DashboardActions.updateDisbursement(disbursement.id, payload);
    }
  };

  const deleteDisbursement = () => {
    DashboardActions.destroyDisbursement(disbursement);
    setShowDeleteModal(false);
  };

  const detailsViewHeader = (
    <tr
      style={{
        height: '50px',
        borderBottom: `1px solid ${grey4}`,
        backgroundColor: 'whitesmoke',
      }}
    >
      <td colSpan={8}>
        <div className="flex flex--space-between">
          <div>
            <Typography fontWeight="500">Disbursement Details</Typography>
          </div>
          {!isLoading && (
            <PendingDisbursementDetailActions
              canDelete={canDeleteDisbursement}
              canPrint={canPrintDisbursement}
              clientId={clientId}
              deletePendingDisbursement={() => {
                setShowDeleteModal(true);
              }}
              disbursement={data}
              isEditing={false} //TODO
              onToggleEdit={() => {
                //TODO
                // eslint-disable-next-line no-console, no-undef
                console.log('edit');
              }}
              putOnHold={putOnHold}
              removeHold={removeHold}
              renderOnHoldDisbursements={renderOnHoldDisbursements}
              sendDisbursementBackToPending={sendDisbursementBackToPending}
              showSendDisbursementBackToPending={showSendDisbursementBackToPending}
            />
          )}
        </div>
      </td>
    </tr>
  );

  const loading = (
    <tr>
      <td colSpan={6}>
        <Box display="flex" justifyContent="center">
          <LinearProgress />
        </Box>
      </td>
    </tr>
  );

  const disbursementInfo = (
    <tr style={{ borderBottom: '1px solid lightgrey' }}>
      <td colSpan={7}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Box className={classes.newFormField}>
              <Box className={classes.newFormLabel}>Payment Type:</Box>{' '}
              <Box className={classes.newFormData}>{paymentMethodLabel}</Box>
            </Box>
            <Box className={classes.newFormField}>
              <Box className={classes.newFormLabel}>Payee:</Box>{' '}
              <Box className={classes.newFormData}>{disbursement.payee_name}</Box>
            </Box>
            {disbursement.payee_street1 && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Payee Address:</Box>{' '}
                <Box className={classes.newFormData}>
                  {`${disbursement.payee_street1 || ''} ${disbursement.payee_street2 || ''}`}
                  {'  '}
                  {` ${disbursement.payee_city || ''}, ${
                    disbursement.payee_state || ''
                  } ${disbursement.payee_zip || ''}`}
                </Box>
              </Box>
            )}
            {disbursement.memo && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Memo:</Box>{' '}
                <Box className={classes.newFormData}>{disbursement.memo}</Box>
              </Box>
            )}
            {disbursement.payeeSpecialInstructions && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Special Instructions:</Box>
                <Box className={classes.newFormData}> {disbursement.payeeSpecialInstructions}</Box>
              </Box>
            )}

            {disbursement.checkNum && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Check Number:</Box>
                <Box className={classes.newFormData}> {disbursement.checkNum}</Box>
              </Box>
            )}
            {disbursement.checkMemoLine && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Check Memo Line:</Box>
                <Box className={classes.newFormData}> {disbursement.checkMemoLine}</Box>
              </Box>
            )}
          </div>
          <div style={{ width: '50%' }}>
            {data?.attributes.checkMemoLine && (
              <div className={classes.newFormField}>
                <div className={classes.newFormLabel}>Memo Line 2:</div>
                <div className={classes.newFormData}>{data.attributes.checkMemoLine}</div>
              </div>
            )}
            {disbursement.postage_type && (
              <Box className={classes.newFormField}>
                <Box className={classes.newFormLabel}>Postage Type:</Box>
                <Box className={classes.newFormData}> {disbursement.postage_type}</Box>
              </Box>
            )}
            {trustBeneData?.govtBenefitsResults.map((benefit) => (
              <Box className={classes.newFormField} key={benefit.name}>
                <Box className={classes.newFormLabel}>{benefit.name}: </Box>
                <Box className={classes.newFormData}>{benefit.value}</Box>
              </Box>
            ))}
          </div>
        </div>
      </td>
    </tr>
  );

  const disbursementDeleteConfirmationModal = (
    <tr>
      <td>
        <DisbursementDeleteConfirmationModal
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={deleteDisbursement}
        />
      </td>
    </tr>
  );

  const detailsView = (
    <>
      {detailsViewHeader}
      {disbursementInfo}
      <DisbursementCategoryAmounts
        allowInsert={disbursement.payment_method === 'Check'}
        canAddAttachments={data?.meta.canAddAttachments}
        checkInsertAttachmentId={data?.relationships.smartpayablesInsert?.data?.id}
        disbursementCategoryAmounts={disbursementCategoryAmounts}
        disbursementId={disbursementId}
        requested_at={disbursement.requested_at}
        requester={disbursement.requester?.name || disbursement.requester?.email}
      />
      <DisbursementDecisions decisions={decisions} disbursementId={disbursementId} />
      {showDeleteModal && disbursementDeleteConfirmationModal}
    </>
  );

  return <>{!isLoading ? detailsView : loading}</>;
}

PendingDisbursementsDetailView.propTypes = {
  disbursement: PropTypes.object,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
};
