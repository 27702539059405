import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardPendingDisbursements from './pending_disbursements/DashboardPendingDisbursements';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDepositsTabContent from 'react/member/components/dashboard/deposits/DashboardDepositsTabContent';
import DashboardCheckPrinting from 'react/member/components/dashboard/trusts/DashboardCheckPrinting';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardOrganizationPendingTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      disbursementCounts: {},
      oneTimeDisbursementsExist: false,
      onHoldDisbursementsExist: false,
      draftDisbursementsExist: false,
      recurringDisbursementsExist: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardDisbursementStore.on('disbursement.update', this.checkDisbursementNotifications);
    DashboardDisbursementStore.on('disbursement.delete', this.checkDisbursementNotifications);
    DashboardDraftDisbursementStore.on(
      'draftDisbursement.update',
      this.checkDisbursementNotifications,
    );
    DashboardDraftDisbursementStore.on(
      'draftDisbursement.delete',
      this.checkDisbursementNotifications,
    );
    RecurringDisbursementStore.on(
      'recurringDisbursement.update',
      this.checkDisbursementNotifications,
    );
    DashboardBudgetItemStore.on('budgetItem.create', this.checkDisbursementNotifications);
    this.checkDisbursementNotifications();
    // Clients (and their cards) are fetched to ensure that pending disbursement
    // detail edit forms can populate their card dropdowns.
    this.fetchClients();
  }

  componentWillUnmount() {
    DashboardDisbursementStore.off('disbursement.update', this.checkDisbursementNotifications);
    DashboardDisbursementStore.off('disbursement.delete', this.checkDisbursementNotifications);
    DashboardDraftDisbursementStore.off(
      'draftDisbursement.update',
      this.checkDisbursementNotifications,
    );
    DashboardDraftDisbursementStore.off(
      'draftDisbursement.delete',
      this.checkDisbursementNotifications,
    );
    RecurringDisbursementStore.off(
      'recurringDisbursement.update',
      this.checkDisbursementNotifications,
    );
    DashboardBudgetItemStore.off('budgetItem.create', this.checkDisbursementNotifications);
  }

  checkDisbursementNotifications() {
    const promises = [
      this.getDisbursementNotification(),
      this.getOnHoldDisbursementNotification(),
      this.getDraftDisbursementNotification(),
      this.getRecurringDisbursementNotification(),
      this.fetchExternalChecks(),
    ];
    Promise.allSettled(promises).then((results) => {
      this.setState({
        isLoading: false,
        oneTimeDisbursementsExist: this.extractPromiseResult(results[0]),
        onHoldDisbursementsExist: this.extractPromiseResult(results[1]),
        draftDisbursementsExist: this.extractPromiseResult(results[2]),
        recurringDisbursementsExist: this.extractPromiseResult(results[3]),
        disbursementCounts: {
          externalCheck: this.extractPromiseResult(results[4]),
        },
      });
    });
  }

  extractPromiseResult(result) {
    return result.status === 'fulfilled' ? result.value : false;
  }

  getDisbursementNotification() {
    const url = this.props.organization
      ? RailsRoutes.dashboard_organization_disbursements_notification_icon_path(
          this.props.organization.slug,
        )
      : RailsRoutes.disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' });
  }

  getDraftDisbursementNotification() {
    const url = this.props.organization
      ? RailsRoutes.dashboard_organization_draft_disbursements_notification_icon_path(
          this.props.organization.slug,
        )
      : RailsRoutes.draft_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' });
  }

  getOnHoldDisbursementNotification() {
    const url = this.props.organization
      ? RailsRoutes.dashboard_organization_on_hold_disbursements_notification_icon_path(
          this.props.organization.slug,
        )
      : RailsRoutes.on_hold_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' });
  }

  getRecurringDisbursementNotification() {
    const url = this.props.organization
      ? RailsRoutes.dashboard_organization_recurring_disbursements_notification_icon_path(
          this.props.organization.slug,
        )
      : RailsRoutes.recurring_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' });
  }

  fetchExternalChecks() {
    const { organization } = this.props;
    if (organization) {
      return axios
        .get(RailsRoutes.count_dashboard_organization_external_checks_path(organization.slug))
        .then(({ data }) => data.count);
    }

    return axios
      .get(RailsRoutes.count_dashboard_external_checks_path())
      .then(({ data }) => data.count);
  }

  fetchClients() {
    const { organization } = this.props;
    if (organization) {
      return DashboardActions.fetchClientsByOrganization(organization.slug);
    }
    // Multi-org users will need all clients in their scope:
    return DashboardActions.fetchClients();
  }

  tabs() {
    return [
      {
        id: 'organization-approvals-tab',
        title: 'Approvals',
        generic:
          this.state.oneTimeDisbursementsExist ||
          this.state.recurringDisbursementsExist ||
          this.state.onHoldDisbursementsExist,
        customNotificationStyle: { top: -6, right: -4 },
      },
      this.props.printsChecks
        ? {
            id: 'organization-print-checks-tab',
            title: 'Print Checks',
            notification: this.state.disbursementCounts.externalCheck,
            customNotificationStyle: { top: -6, right: -4 },
          }
        : undefined,
      this.props.showPendingDeposits
        ? { id: 'organization-deposits-tab', title: 'Deposits' }
        : undefined,
    ].filter(Boolean);
  }

  tabContents() {
    const { organization, showAdditionalApprovalTable, userRoles } = this.props;
    const orgSlug = organization && organization.slug;

    return [
      <TabContent id="organization-approvals-tab" key="organization-approvals-tab-content">
        <DashboardPendingDisbursements
          orgSlug={orgSlug}
          organization={organization || null}
          renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
          showAdditionalApprovalTable={showAdditionalApprovalTable}
          showDraftNotificationIcon={this.state.draftDisbursementsExist}
          showNotificationIcon={this.state.oneTimeDisbursementsExist}
          showOnHoldNotificationIcon={this.state.onHoldDisbursementsExist}
          showRecurringNotificationIcon={this.state.recurringDisbursementsExist}
          showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
          userRoles={userRoles}
        />
      </TabContent>,

      this.props.printsChecks && (
        <TabContent id="organization-print-checks-tab" key="organization-print-checks-tab-content">
          <DashboardCheckPrinting orgSlug={orgSlug} />
        </TabContent>
      ),

      this.props.showPendingDeposits && (
        <TabContent id="organization-deposits-tab" key="organization-deposits-tab-content">
          <DashboardDepositsTabContent
            allOrgs={this.props.allOrgs}
            organization={this.props.organization}
            showMultiDepositsButton={this.props.showMultiDepositsButton}
            tabList={['deposits-pending']}
            userRoles={this.props.userRoles}
          />
        </TabContent>
      ),
    ].filter(Boolean);
  }

  render() {
    return (
      <div className="widescreen-inner-container">
        {this.state.isLoading ? (
          <LoadingIndicator />
        ) : (
          <TabNavigation showBackground tabs={this.tabs()}>
            {this.tabContents()}
          </TabNavigation>
        )}
      </div>
    );
  }
}

DashboardOrganizationPendingTab.propTypes = {
  organization: PropTypes.object,
  showPendingDeposits: PropTypes.bool,
  showAdditionalApprovalTable: PropTypes.bool,
  showMultiDepositsButton: PropTypes.bool,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  printsChecks: PropTypes.bool,
  allOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userRoles: userRoleShape.isRequired,
};
