import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import EffectiveBalancesTooltip from 'react/shared/components/tooltips/EffectiveBalancesTooltip';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DetailBalanceInfo extends React.Component {
  static get propTypes() {
    return {
      currentBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      effectiveBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      balanceInfo: PropTypes.object.isRequired,
      organizationSlug: PropTypes.string.isRequired,
      isPooled: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    bindAll(this);
  }

  renderCurrentBalance() {
    const { currentBalance } = this.props;
    if (currentBalance) {
      return (
        <div className="new-form-field">
          <div className="new-form__label">Account Balance:</div>
          <div className="new-form__data">{asMoney(Number(currentBalance))}</div>
        </div>
      );
    }
  }

  renderEffectiveBalance() {
    const { effectiveBalance, balanceInfo, organizationSlug, isPooled } = this.props;

    if (effectiveBalance) {
      return (
        <div className="new-form-field">
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <EffectiveBalancesTooltip
              balanceInfo={balanceInfo}
              isPooled={isPooled}
              organizationSlug={organizationSlug}
            >
              <Typography
                sx={{
                  display: 'inline',
                  fontSize: '18px',
                  fontWeight: 600,
                  minHeight: '34px',
                  paddingTop: '7px',
                  marginBottom: '10px',
                  marginRight: '15px',
                  borderBottom: `1px dashed ${PALETTE.grey3}`,
                  fontFamily: '"Calibre", sans-serif',
                  letterSpacing: '0px',
                }}
              >
                3-month effective balance:
              </Typography>
            </EffectiveBalancesTooltip>
            <div className="new-form__data">{asMoney(Number(effectiveBalance))}</div>
          </Box>
        </div>
      );
    }
  }

  render() {
    const { currentBalance, effectiveBalance } = this.props;
    return currentBalance || effectiveBalance ? (
      <div className="new-form__section new-form--compact new-form__section--subheader">
        <div style={{ width: '50%', paddingRight: 20 }}>{this.renderCurrentBalance()}</div>
        <div style={{ width: '50%', paddingLeft: 20 }}>{this.renderEffectiveBalance()}</div>
      </div>
    ) : (
      <div />
    );
  }
}
