import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import SocialIcon from 'react/shared/components/footer/SocialIcon';
import TrueLinkIconAndTitle from 'react/shared/components/svg/TrueLinkIconAndTitle';
import TrueLinkAppStoreButton from 'react/shared/components/true_link/main/TrueLinkAppStoreButton';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: PALETTE.grey,
  },
  content: {
    margin: '0 auto',
    maxWidth: '940px',
    minWidth: '440px',
    padding: '50px 0',
    [theme.breakpoints.down('md')]: {
      padding: '50px',
      flexDirection: 'column',
      gap: '20px',
    },
  },
  logo: {
    height: '40px',
    marginLeft: '0',
    width: '133px',
    paddingBottom: '10px',
  },
  socialIcons: {
    paddingTop: '15px',
    '& a svg': {
      color: PALETTE.evergreen,
      fontSize: '40px',
      marginRight: '15px',
    },
  },
  mobileCTAButtons: {
    display: 'flex',
    paddingTop: '25px',
    alignItems: 'center',
    '& div a': {
      width: '160px !important',
    },
  },
  footerStatement: {
    marginTop: '30px',
    paddingTop: '20px',
    fontSize: '15px',
    color: PALETTE.grey2,
    borderTop: `1px solid ${PALETTE.grey3}`,
    '& a': {
      color: PALETTE.grey2,
    },
  },
}));

const socialIcons = (
  <>
    <SocialIcon
      icon="facebook-square fab"
      link="https://www.facebook.com/truelinkfin"
      title="Facebook"
    />
    <SocialIcon
      icon="linkedin fab"
      link="https://www.linkedin.com/company/true-link-financial"
      title="Linked In"
    />
    <SocialIcon icon="twitter-square fab" link="https://twitter.com/TrueLinkFin" title="Twitter" />
    <SocialIcon icon="vimeo-square fab" link="https://vimeo.com/truelinkfinancial" title="Vimeo" />
  </>
);

const mobileCTAButtons = (
  <>
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Apple Store Button' }}
      store="ios"
      style={{ height: '75px', width: '160px' }}
    />
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Google Play Store Button' }}
      store="android"
      style={{ height: '75px', width: '160px', marginLeft: '15px' }}
    />
  </>
);

const handleContactUs = (event) => {
  if (window.zE) {
    event.preventDefault();
    window.zE('messenger', 'open');
  }
};

const links = (
  <>
    <div className="footer-section" />
    <div className="footer-section" />
    <div className="footer-section">
      <div className="foothead">
        <b>About</b>
      </div>
      <a className="footerlink" href="/about-us">
        About us
      </a>
      <a className="footerlink" href="/in-the-news">
        True Link news
      </a>
      <a className="footerlink" href="/about-us/careers">
        Careers
      </a>
      <a className="footerlink" href="/about-us/leadership">
        Leadership
      </a>
      <a className="footerlink" href="/impact">
        Our impact
      </a>
    </div>
    <div className="footer-section">
      <div className="foothead">
        <b>Resources</b>
      </div>
      <a className="footerlink" href="/faq">
        FAQ
      </a>
      <a className="footerlink" href="/your-security">
        Your security
      </a>
      <a className="footerlink" href="/prepaid-card/schedule-of-fees-and-charges">
        Fees
      </a>
      <a className="footerlink" href="/compliance-guide">
        Compliance guide
      </a>
      <a className="footerlink" href="/request-brochure">
        Request a brochure
      </a>
      <a className="footerlink" href="/referral-program">
        Share True Link
      </a>
    </div>
    <div className="footer-section">
      <a
        className="footerlink"
        href="/contact-us"
        onClick={handleContactUs}
        style={{ color: '#164ad4', fontSize: '20px', marginTop: '3px' }}
      >
        Contact us
      </a>
    </div>
  </>
);

export default function ReferralFooter(props) {
  const classes = useStyles();
  const content = {
    copyright: '© 2022 True Link. All Rights Reserved.',
    waiver:
      '*True Link will waive its monthly fee for three consecutive months once the new Visa card has been activated. This offer applies to new True Link Visa card administrators, customers, or first-time cardholders, and will only apply to the first activated True Link Visa card.',
    legalLinks: (
      <>
        <a href="/privacy-policy">Privacy and Security</a> |{' '}
        <a href="/terms-of-service">Terms of service</a>
        <a href="/privacy-policy-3">Privacy policy</a> |{' '}
        <a href="/ada-accessibility">ADA Accessibility</a> |{' '}
        <a
          href="https://member.truelinkfinancial.com/legal/next-step-master-services-agreement"
          rel="noopener noreferrer"
        >
          Master Services Agreement (Cardholder Agreement)
        </a>{' '}
        | &nbsp;
        <a
          href="https://assets.website-files.com/61000b6dd512bb24bee0534c/6149f1d0557516351ab6ce01_Prepaid-Card-Sunrise-Banks-Privacy-Notice-Safe-Harbor.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Sunrise Banks Privacy Policy
        </a>
        &nbsp;|{' '}
        <a href="https://adviserinfo.sec.gov/firm/summary/281870" rel="noreferrer" target="_blank">
          ADV2A
        </a>{' '}
        |{' '}
        <a
          href="https://reports.adviserinfo.sec.gov/crs/crs_281870.pdf"
          rel="noreferrer"
          target="_blank"
        >
          <b>Form CRS</b>
        </a>
      </>
    ),
    visa: (
      <>
        <span>
          <sup>1</sup>Visa’s Zero Liability Policy does not apply to certain commercial card and
          anonymous prepaid card transactions or transactions not processed by Visa. Cardholders
          must use care in protecting their card and notify their issuing financial institution
          immediately of any authorized use. Contact your issuer for more details
        </span>
        <br />
        <span>
          <sup>2</sup>Not associated with or endorsed by the Social Security Administration or any
          other government agency
        </span>
        <br />
        <span>
          <sup>3</sup>Learn more at{' '}
          <a href="/compliance" rel="noreferrer" target="_blank">
            truelinkfinancial.com/compliance
          </a>
          {' .'}
        </span>
      </>
    ),
    apps: (
      <>
        <span>App Store, Apple and the Apple log are trademarks of Apple Inc.</span>
        <br />
        <span>Google Play and the Google Play Logo are trademarks of Google LLC.</span>
      </>
    ),
    agreement:
      '**Please see Cardholder Agreement for additional fees that True Link may charge for the Visa card. Third parties may charge fees of their own based on your use of the Visa card (e.g., your bank for funding transfers, an ATM operator for cash withdrawals)',
    investmentManagementServices:
      'Investment Management Services are provided through True Link Financial Advisors, LLC, (the “Adviser”) an investment adviser registered with the U.S. Securities and Exchange Commission (“SEC”) and wholly-owned subsidiary of True Link Financial, Inc. (“True Link Financial” and, together with the Adviser, “True Link”) Registration with the SEC authorities as a Registered Investment Adviser does not imply a certain level of skill or training nor does it constitute an endorsement of the advisory firm by the SEC. Adviser only provides investment management services upon entering into an Investment Advisory Agreement (IAA) with a client. With respect to pooled trust clients, upon entering into an IAA, the client is the trust; beneficiaries of the trust are not investment advisory clients of Adviser. Nothing contained on this website should be considered an offer to sell or a solicitation to buy any securities. Non-deposit investment products and trust services offered by True Link Financial Advisors are not insured or guaranteed by the FDIC or any other government agency, are not obligations of any bank, and are subject to risk, including loss of principal.',
    trueLink1:
      'True Link Financial, Inc. provides the trust administration software and record-keeping platform as well as the True Link Visa Prepaid Card and associated software platform.',
    trueLink2:
      'True Link Financial, Inc. does not provide investment management services and neither True Link Financial, Inc. nor True Link Financial Advisors, LLC provides legal or tax advice. Please consult the appropriate advisor regarding your specific circumstances. True Link is not associated or endorsed by the Social Security Administration or any other governmental agency.',
    trueLinkVisa:
      'The True Link Visa Prepaid Card is issued by Sunrise Banks N.A., St. Paul, MN 55103, Member FDIC, pursuant to a license from Visa U.S.A. Inc. This Card can be used everywhere Visa debit cards are accepted. Use of this card constitutes acceptance of the terms and conditions stated in the Cardholder Agreement.',
  };

  return (
    <div className={classes.container} data-testid="footerCard">
      <div className={classes.content}>
        <Grid alignItems="flex-start" container direction="row" justifyContent="space-between">
          <div className="footer-section" id="contact">
            <TrueLinkIconAndTitle aria-label="logo" className={classes.logo} />
            <Typography variant="body2">Financial services for people with complex</Typography>
            <Typography variant="body2">needs and the people who care for them.</Typography>
            <div className={classes.socialIcons}>{socialIcons}</div>
            <div className={classes.mobileCTAButtons}> {mobileCTAButtons}</div>
          </div>
          {links}
        </Grid>
        <hr />
        <div className={classes.footerStatement}>
          <p>{content.copyright}</p>
          <p>{content.legalLinks}</p>
          {!props.isCareAgencyExperiment && <p>{content.waiver}</p>}
          <p>{content.visa}</p>
          <p>{content.apps}</p>
          <p>{content.agreement}</p>
          <p>{content.investmentManagementServices}</p>
          <p>{content.trueLink1}</p>
          <p>{content.trueLink2}</p>
          <p>{content.trueLinkVisa}</p>
        </div>
      </div>
    </div>
  );
}

ReferralFooter.propTypes = {
  isCareAgencyExperiment: PropTypes.bool,
};
