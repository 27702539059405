import PropTypes from 'prop-types';
import React from 'react';
import OldDashboardCard from './cards/OldDashboardCard';
import IndividualTrustBeneficiaryOverview from './overview/IndividualTrustBeneficiaryOverview';
import DeprecatedWealthAccountPage from './wealth_accounts/DeprecatedWealthAccountPage';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CategorizedBudget from 'react/member/components/dashboard/clients/categorized_budget/CategorizedBudget';
import IndividualDepositsTab from 'react/member/components/dashboard/deposits/individual_trusts/IndividualDepositsTab';
import DisbursementTabsWrapper from 'react/member/components/dashboard/disbursements/tab_content/DisbursementTabsWrapper';
import DashboardPaginatedPayeeList from 'react/member/components/dashboard/payees/DashboardPaginatedPayeeList';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';
import Reports from 'react/member/components/dashboard/reports/Reports';
import DashboardTrustBeneficiaryProfile from 'react/member/components/dashboard/trust_beneficiaries/profile/DashboardTrustBeneficiaryProfile';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardPositionStore from 'react/member/stores/DashboardPositionStore';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';

// Note: an "Individual Client" in this context is specifically referring to a client that is a part of an
// individual trust rather than a pooled trust.
export default class DashboardIndividualClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revertedClientAccountsTab: false,
      accounts: [],
      positions: [],
    };

    bindAll(this);
  }

  getChildContext() {
    return { client: this.props.client };
  }

  componentDidMount() {
    DashboardPositionStore.on('positions.fetchByClient', this.onChangePositions);

    DashboardActions.fetchPositionsByClient(this.props.client.slug);
    DashboardActions.fetchDisbursements(this.props.client.slug);
    DashboardActions.fetchRecurringDisbursements(this.props.client.slug);
  }

  componentWillUnmount() {
    DashboardPositionStore.off('positions.fetchByClient', this.onChangePositions);
  }

  onChangePositions() {
    this.setState({ positions: DashboardPositionStore.getPositions() });
  }

  tabDefs() {
    return {
      accounts: {
        id: 'client-accounts-tab',
        title: this.props.showIndividualTrustBeneficiaryOverview ? 'Overview' : 'Accounts',
        type: 'choseAccounts',
        tabContent: this.accountsTab(),
      },
      disbursements: {
        id: 'client-disbursements-tab',
        title: 'Disbursements',
        type: 'choseDisbursements',
        tabContent: this.disbursementsTab(),
      },
      deposits: {
        id: 'client-deposits-tab',
        title: 'Deposits',
        type: 'choseDeposits',
        tabContent: this.depositsTab(),
      },
      payees: {
        id: 'client-payees-tab',
        title: 'Payees',
        type: 'chosePayees',
        tabContent: this.payeesTab(),
      },
      reports: {
        id: 'client-reports-tab',
        title: 'Reports',
        type: 'choseReports',
        tabContent: this.reportsTab(),
      },
      card: {
        id: 'client-card-tab',
        title: 'True Link Card',
        type: 'choseCard',
        tabContent: this.trueLinkCardTab(),
      },
      budgetItems: {
        id: 'client-budget-items-tab',
        title: 'Budget',
        type: 'choseBudgetItems',
        tabContent: this.budgetItemsTab(),
      },
      notes: {
        id: 'client-notes-tab',
        title: 'Profile',
        type: 'choseNotes',
        tabContent: this.beneficiaryProfileTab(),
      },
    };
  }

  tabList() {
    const { accounts, disbursements, deposits, payees, reports, card, budgetItems, notes } =
      this.tabDefs();
    const hideCardTab = isRestrictedViewUser() || this.props.showIndividualTrustBeneficiaryOverview;

    return [
      accounts,
      disbursements,
      deposits,
      payees,
      reports,
      ...(hideCardTab ? [] : [card]),
      budgetItems,
      notes,
    ];
  }

  handleRevertClientAccountsTab() {
    this.setState({
      revertedClientAccountsTab: true,
    });
  }

  accountsTab() {
    if (
      this.props.showIndividualTrustBeneficiaryOverview &&
      !this.state.revertedClientAccountsTab
    ) {
      return (
        <TabContent id="client-accounts-tab" key="client-accounts-tab">
          <IndividualTrustBeneficiaryOverview
            client={this.props.client}
            onRevert={this.handleRevertClientAccountsTab}
            userRoles={this.props.userRoles}
          />
        </TabContent>
      );
    }

    return (
      <TabContent id="client-accounts-tab" key="client-accounts-tab">
        <DeprecatedWealthAccountPage
          client={this.props.client}
          investmentAccountId={this.props.investmentAccountId}
          showAdvanceOption={this.props.showAdvanceOption}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  disbursementsTab() {
    return (
      <TabContent id="client-disbursements-tab" key="client-disbursements-tab">
        <DisbursementTabsWrapper
          disbursementsFor={'Trust Beneficiary'}
          displayedPaymentMethods={this.props.client.trust.displayed_payment_types}
          editExternalCheckDisposition={this.props.editExternalCheckDisposition}
          initialClient={this.props.client}
          initialClientSlug={this.props.client.slug}
          organizationSlug={this.props.client.organization.slug}
          renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
          showCashBalance={this.props.showCashBalance}
          showIndividualTrustBeneficiaryOverview={this.props.showIndividualTrustBeneficiaryOverview}
          showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
          threeMonthInfo={this.props.threeMonthInfo}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  depositsTab() {
    return (
      <TabContent id="client-deposits-tab" key="client-deposits-tab">
        <IndividualDepositsTab clientSlug={this.props.client.slug} />
      </TabContent>
    );
  }

  payeesTab() {
    return (
      <TabContent id="client-payees-tab" key="client-payees-tab">
        <DashboardPaginatedPayeeList client={this.props.client} userRoles={this.props.userRoles} />
      </TabContent>
    );
  }

  reportsTab() {
    const { person, slug, trust } = this.props.client;
    const origin = {
      id: slug,
      name: person.full_name_w_nick,
      type: originTypes.bene,
      customStatementDates: this.props.availableCustomStatementDates,
      latestTrustStatementEndDate: trust.latest_statement_end_date,
      quarterlyStatementDates: this.props.quarterlyStatementDates,
    };
    return (
      <TabContent id="client-reports-tab" key="client-reports-tab">
        <Reports origin={origin} />
      </TabContent>
    );
  }

  trueLinkCardTab() {
    return (
      <TabContent id="client-card-tab" key="client-card-tab">
        <OldDashboardCard client={this.props.client} userRoles={this.props.userRoles} />
      </TabContent>
    );
  }

  budgetItemsTab() {
    return (
      <TabContent id="client-budget-items-tab" key="client-budget-items-tab">
        <CategorizedBudget trustBeneficiary={this.props.client} />
      </TabContent>
    );
  }

  beneficiaryProfileTab() {
    return (
      <TabContent id="client-notes-tab" key="client-notes-tab">
        <DashboardTrustBeneficiaryProfile
          trustBeneficiarySlug={this.props.client.slug}
          userRoles={this.props.userRoles}
        />
      </TabContent>
    );
  }

  render() {
    return (
      <div className="widescreen-inner-container">
        <TabNavigation
          classOptions={'nav nav-tabs wm-dash-tabs'}
          showBackground
          tabs={this.tabList()}
        >
          {this.tabList().map((tab) => tab.tabContent || [])}
        </TabNavigation>
      </div>
    );
  }
}

DashboardIndividualClient.childContextTypes = {
  client: PropTypes.object,
};

DashboardIndividualClient.propTypes = {
  availableCustomStatementDates: PropTypes.shape({
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  client: PropTypes.object.isRequired,
  editExternalCheckDisposition: PropTypes.bool,
  investmentAccountId: PropTypes.number.isRequired,
  showCashBalance: PropTypes.bool,
  showAdvanceOption: PropTypes.bool,
  threeMonthInfo: PropTypes.object,
  renderOnHoldDisbursements: PropTypes.bool,
  quarterlyStatementDates: PropTypes.array.isRequired,
  showSendDisbursementBackToPending: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
  showIndividualTrustBeneficiaryOverview: PropTypes.bool,
};
