import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CurrentCardDisplay from './display/CurrentCardDisplay';
import Section from 'react/member/card/components/account_tab/card_status/Section';
import CardStatusActions from 'react/member/card/components/account_tab/card_status/actions/CardStatusActions';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import { cardStatusSortingHat, CARD_STATUSES } from 'react/member/utils/CardStatus';
import localeShape from 'react/shared/shapes/locale_shape';

export default function CurrentCardStatus({
  account,
  backupCards,
  card,
  cardBalance,
  cardReissues,
  cardholder,
  localeProps,
  onCardActivationSuccess,
  onCardCloseSuccess,
  onCardDeactivateReactivateSuccess,
  onCardReissueSuccess,
  organization,
}) {
  const status = cardStatusSortingHat(account, card);
  const showFooter = [
    // first card order
    CARD_STATUSES.OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS,
    // additional card order
    CARD_STATUSES.OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    // legacy card order (reissue C - new account)
    CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS,
    // replacement card order (reissue A)
    CARD_STATUSES.OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    // fraud block status
    CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS,
    // card
    CARD_STATUSES.INACTIVE_CARD_STATUS,
    CARD_STATUSES.LOST_CARD_STATUS,
    CARD_STATUSES.OPEN_CARD_STATUS,
  ].includes(status);
  const hasPendingReplacement =
    backupCards.length > 0 || cardReissues.length > 0 || !!card?.card_reissue;
  const expirationThreshold = card?.expiry_date
    ? moment(card.expiry_date).subtract(56, 'days')
    : null;
  const expiresSoonOrExpired = card?.expiry_date
    ? moment().isSameOrAfter(expirationThreshold, 'day')
    : null;
  const showExpiringCardBox =
    card?.expiry_date &&
    expiresSoonOrExpired &&
    !hasPendingReplacement &&
    !(card?.is_breached ?? false) &&
    [CARD_STATUSES.INACTIVE_CARD_STATUS, CARD_STATUSES.OPEN_CARD_STATUS].includes(status);

  return (
    <Section
      footer={
        showFooter && (
          <CardStatusActions
            account={account}
            backupCards={backupCards}
            card={card}
            cardReissues={cardReissues}
            cardholder={cardholder}
            localeProps={localeProps}
            onCardActivationSuccess={onCardActivationSuccess}
            onCardCloseSuccess={onCardCloseSuccess}
            onCardDeactivateReactivateSuccess={onCardDeactivateReactivateSuccess}
            onCardReissueSuccess={onCardReissueSuccess}
            organization={organization}
            showExpiringCardBox={showExpiringCardBox}
            status={status}
          />
        )
      }
    >
      <CurrentCardDisplay
        account={account}
        card={card}
        cardBalance={cardBalance}
        cardholder={cardholder}
        onCardReissueSuccess={onCardReissueSuccess}
        organization={organization}
        showExpiringCardBox={showExpiringCardBox}
        status={status}
      />
    </Section>
  );
}

CurrentCardStatus.propTypes = {
  account: accountShape.isRequired,
  backupCards: PropTypes.arrayOf(cardShape).isRequired,
  card: cardShape,
  cardBalance: PropTypes.number,
  cardReissues: PropTypes.arrayOf(cardOrderShape).isRequired,
  cardholder: cardholderShape.isRequired,
  localeProps: localeShape.isRequired,
  onCardActivationSuccess: PropTypes.func.isRequired,
  onCardCloseSuccess: PropTypes.func.isRequired,
  onCardDeactivateReactivateSuccess: PropTypes.func.isRequired,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organization: organizationShape.isRequired,
};
