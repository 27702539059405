// Used in accounts and users views. Handles change events in customer_service_note fields and makes an ajax call to update the db
$(document).ready(function () {
  $('.customer_service_note').each(function (idx, elem) {
    elem = $(elem);
    var prev_val = elem.html();
    elem.on('blur', function () {
      if (elem.html() == prev_val) return;
      prev_val = elem.html();
      $.post(
        elem.attr('data-href'),
        {
          _method: elem.attr('data-method'),
          format: 'json',
          'customer_service_note[note_content]': elem.html(),
        },
        function (response) {
          elem.attr('data-href', response.path || elem.attr('data-base-href'));
          elem.attr('data-method', response.path ? 'put' : 'post'); // Switches between PUT and POST http requests depending on whether its a new note or an update
          elem.css('opacity', 0);
          elem.animate(
            {
              opacity: 1,
            },
            200,
          );
        },
      );
    });
  });
});

// Used in transfers 'open requests' view to handle clicks of Send Text and Mark Complete buttons
$(document).ready(function () {
  // mark any form to submit with ajax
  $('.flash_on_ajax_response').on('ajax:success', function (e, data, status, xhr) {
    // $form = $(this).closest("form");
    $(this).css('opacity', 0).animate(
      {
        opacity: 1,
      },
      200,
    );
  });
});

// Handles ajaxy stuff on the users page
$(document).ready(function () {
  // Multiple accounts checkbox
  $('#checkbox_multiple_accounts').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#multiple_accounts_success_text').text('');

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      data: {
        organization: { multiple_accounts_flag: checked },
      },
    }).done(function () {
      $('#multiple_accounts_success_text').text('UPDATED').css('color', 'green');
    });
  });

  $.each($('p.allowed-card-programs > input'), function (index, el) {
    $(el).change(function () {
      $('#card_program_success_text').text('');
      var $this = $(this);
      var $notificationArea = $('#card_program_success_text');
      var checked = $this.is(':checked') ? 1 : 0;
      var paramHash = { card_programs: {} };

      paramHash['card_programs'][$this.attr('name')] = checked;

      $.ajax({
        url: $this.attr('data-form-path'),
        type: 'PUT',
        data: {
          organization: paramHash,
        },
      }).done(function () {
        $notificationArea.text('UPDATED!').css('color', 'green');
        window.setTimeout(function () {
          $notificationArea.text('');
        }, 3000);
      });
    });
  });

  $('.gov-benefits-checkbox').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#government_benefits_success_text').text('');
    var o = {};
    o[$(this).val()] = checked;

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      format: 'json',
      data: {
        organization: { government_benefit_id: o },
      },
    }).done(function () {
      $('#government_benefits_success_text').text('UPDATED').css('color', 'green');
    });
  });

  // Allow ach fees checkbox
  $('#checkbox_ach_fees').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#ach_fees_success_text').text('');

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      data: {
        organization: { show_ach_fees: checked },
      },
    }).done(function () {
      $('#ach_fees_success_text').text('UPDATED').css('color', 'green');
    });
  });
});

$(document).ready(function () {
  if ($('#organization_select').length) {
    $('#organization_select').select2({
      ajax: {
        url: RailsRoutes.users_organization_select_path(),
        cache: false,
        dataType: 'json',
      },
      allowClear: true,
      placeholder: '(None)',
    });
    $('#organization_select').next().css('margin-left', '30px');
  }
});
