import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import * as Yup from 'yup';
import ConnectionModalLabel from './ConnectionModalLabel';
import DashboardTrustBeneficiaryProfileConnectionRelationship from './DashboardTrustBeneficiaryProfileConnectionRelationship';
import connectionShape from 'react/member/shapes/ConnectionShape';
import Actions from 'react/shared/actions/actions';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardTrustBeneficiaryProfileConnectionForm extends React.Component {
  constructor(props) {
    super(props);

    this.isEdit = !!this.props.connection.id;

    bindAll(this);
  }

  initialValues() {
    const { connection } = this.props;
    return {
      connection: {
        email: connection.email || '',
        id: connection.id || '',
        isCommon: connection.isCommon || false,
        notes: connection.notes || '',
        companyName: connection.companyName || '',
        person: {
          firstName: connection.person.firstName || '',
          lastName: connection.person.lastName || '',
          mobile: connection.person.mobile || '',
        },
        address: {
          street1: connection.address.street1 || '',
          street2: connection.address.street2 || '',
          city: connection.address.city || '',
          state: connection.address.state || '',
          zip: connection.address.zip || '',
        },
        connectionRelationship: {
          social: connection.connectionRelationship.social || '',
          professional: connection.connectionRelationship.professional || '',
        },
      },
    };
  }

  validationSchema() {
    return Yup.object().shape({
      connection: Yup.object().shape({
        companyName: Yup.string()
          .nullable()
          .when('person', {
            is: (person) => !person.firstName && !person.lastName,
            then: () => Yup.string().required('First/Last Name or Company Name are required'),
          }),
        email: Yup.string().nullable(),
        isCommon: Yup.boolean(),
        notes: Yup.string().nullable(),
        address: Yup.object().shape({
          street1: Yup.string().nullable(),
          street2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string().nullable(),
        }),
        connectionRelationship: Yup.object().shape({
          social: Yup.string().nullable(),
          professional: Yup.string().nullable(),
        }),
        person: Yup.object().shape({
          firstName: Yup.string().nullable(),
          lastName: Yup.string().nullable(),
          mobile: Yup.string().nullable(),
        }),
      }),
    });
  }

  onSubmit(values, { setSubmitting }) {
    const { trustBeneficiarySlug } = this.props;

    const onRequestCompleted = () => {
      setSubmitting(false);
    };
    const action = this.isEdit ? Actions.updateConnection : Actions.createConnection;
    action(trustBeneficiarySlug, values.connection, onRequestCompleted);
  }

  renderError(fieldName) {
    return (
      <ErrorMessage name={fieldName}>
        {(msg) => <div className="form-error">{msg}</div>}
      </ErrorMessage>
    );
  }

  renderStringField(label, name, id) {
    return (
      <div>
        <ConnectionModalLabel inputId={id} labelContent={label}>
          <Field id={id} name={name} type="text" />
        </ConnectionModalLabel>
        {this.renderError(name)}
      </div>
    );
  }

  renderCompanyNameField() {
    return this.renderStringField(
      'Company Name',
      'connection.companyName',
      'connection-company-name',
    );
  }

  render() {
    return (
      <TrueLinkModal
        handleClose={this.props.onClose}
        title={this.isEdit ? 'Edit Connection' : 'New Connection'}
      >
        <Formik
          initialValues={this.initialValues()}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema()}
        >
          {({ values, isSubmitting, isValid, setFieldValue, setFieldTouched }) => {
            const changeFieldValue = function (name, val) {
              setFieldValue(name, val);
            };
            return (
              <Form>
                <div>
                  <ConnectionModalLabel inputId="connection-first-name" labelContent="First Name">
                    <Field
                      id="connection-first-name"
                      name="connection.person.firstName"
                      onBlur={() => {
                        setFieldTouched('connection.companyName', true);
                      }}
                      type="text"
                    />
                  </ConnectionModalLabel>
                  {this.renderError('connection.person.firstName')}
                </div>
                <div>
                  <ConnectionModalLabel inputId="connection-last-name" labelContent="Last Name">
                    <Field
                      id="connection-last-name"
                      name="connection.person.lastName"
                      onBlur={() => {
                        setFieldTouched('connection.companyName', true);
                      }}
                      type="text"
                    />
                  </ConnectionModalLabel>
                  {this.renderError('connection.person.lastName')}
                </div>
                {this.renderCompanyNameField()}
                {this.renderStringField('Phone', 'connection.person.mobile', 'connection-phone')}
                {this.renderStringField('Email', 'connection.email', 'connection-email')}
                {this.renderStringField(
                  'Street 1',
                  'connection.address.street1',
                  'connection-street1',
                )}
                {this.renderStringField(
                  'Street 2',
                  'connection.address.street2',
                  'connection-street2',
                )}
                {this.renderStringField('City', 'connection.address.city', 'connection-city')}
                {this.renderStringField('State', 'connection.address.state', 'connection-state')}
                {this.renderStringField('Zip', 'connection.address.zip', 'connection-zip')}
                <ConnectionModalLabel
                  inputId="connection-professional-relationship"
                  labelContent="Professional Relationship"
                >
                  <DashboardTrustBeneficiaryProfileConnectionRelationship
                    handleChange={(event) =>
                      changeFieldValue(
                        'connection.connectionRelationship.professional',
                        event.target.value,
                      )
                    }
                    id="connection-professional-relationship"
                    trustBeneficiarySlug={this.props.trustBeneficiarySlug}
                    type="professionalRelationship"
                    value={values.connection.connectionRelationship.professional}
                  />
                </ConnectionModalLabel>
                <ConnectionModalLabel
                  inputId="connection-social-relationship"
                  labelContent="Social Relationship"
                >
                  <DashboardTrustBeneficiaryProfileConnectionRelationship
                    handleChange={(event) =>
                      changeFieldValue(
                        'connection.connectionRelationship.social',
                        event.target.value,
                      )
                    }
                    id="connection-social-relationship"
                    trustBeneficiarySlug={this.props.trustBeneficiarySlug}
                    type="socialRelationship"
                    value={values.connection.connectionRelationship.social}
                  />
                </ConnectionModalLabel>
                <ConnectionModalLabel
                  inputId="connection-is-common"
                  labelContent={
                    <span>
                      Shared Connection
                      <InfoTooltip
                        placement="top"
                        tooltipText='A "Shared Connection" is available to be attached to multiple beneficiaries. Changes made to a "Shared Connection" will be reflected on all beneficiaries using the connection.'
                      />
                    </span>
                  }
                >
                  {this.isEdit && (
                    <span id="connection-is-common">
                      {values.connection.isCommon ? 'Yes' : 'No'}
                    </span>
                  )}
                  {!this.isEdit && (
                    <Field id="connection-is-common" name="connection.isCommon" type="checkbox" />
                  )}
                </ConnectionModalLabel>
                <ConnectionModalLabel inputId="connection-notes" labelContent="Notes">
                  <Textarea
                    id="connection-notes"
                    minRows={3}
                    name="notes"
                    onChange={(event) => changeFieldValue('connection.notes', event.target.value)}
                    value={values.connection.notes}
                  />
                  {values.connection.isCommon && (
                    <p className="gray-text">
                      This note is attached to the "Shared Connection" and is not unique to any
                      single beneficiary
                    </p>
                  )}
                </ConnectionModalLabel>
                <div className="tl-form-actions-row" style={{ margin: '1em' }}>
                  <TrueLinkButton
                    className={classNames('btn btn-success', {
                      disabled: !isValid || isSubmitting,
                    })}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="none"
                  >
                    {isSubmitting ? 'Saving...' : 'Save Connection'}
                  </TrueLinkButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </TrueLinkModal>
    );
  }
}

DashboardTrustBeneficiaryProfileConnectionForm.propTypes = {
  onClose: PropTypes.func,
  connection: connectionShape,
  trustBeneficiarySlug: PropTypes.string.isRequired,
};

DashboardTrustBeneficiaryProfileConnectionForm.defaultProps = {
  connection: {
    person: {
      firstName: null,
      lastName: null,
      mobile: null,
    },
    address: {
      street1: null,
      street2: null,
      city: null,
      state: null,
      zip: null,
    },
    connectionRelationship: {
      social: null,
      professional: null,
    },
    isCommon: false,
    companyName: null,
    notes: null,
  },
};
