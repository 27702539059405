import Box from '@mui/material/Box';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import PendingDisbursementAdminApprovalModal from 'react/member/components/dashboard/disbursements/PendingDisbursementAdminApprovalModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function ApprovalButtons(row, organizationSlug) {
  const disbursementId = row.original.id;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [disbursement, setDisbursement] = useState(false);

  //TODO - adjust for recurring view
  //const entity = disbursement || recurringDisbursement;
  const recurringDisbursement = null;
  const isRecurringView = !!recurringDisbursement;

  //TODO - we should try to fetch this data
  const isApprovable = true; //entity.can_be_approved;

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSubmitComplete = useCallback(() => {
    setIsSubmitting(false);
  }, []);

  const openDenyModal = () => {
    if (isRecurringView) {
      return DashboardActions.openRecurringDisbursementDenialModal(disbursementId, 'Recurring');
    }
    return DashboardActions.openDisbursementDenialModal(disbursementId, 'OneTime');
  };

  const approveEntity = (userId) => {
    setIsSubmitting(true);
    if (isRecurringView) {
      DashboardActions.approveRecurringDisbursement(disbursement.id, userId, handleSubmitComplete);
    } else {
      DashboardActions.approveDisbursement(disbursement, userId, handleSubmitComplete);
    }
    setModalOpen(false);
    setIsSubmitting(false);
  };

  const approveOrOpenModal = (entity) => {
    if (entity.admin_approval) {
      setModalOpen(true);
    } else {
      approveEntity(null);
    }
  };

  const fetchData = async () => {
    const disbursementUrl = RailsRoutes.dashboard_organization_disbursement_path(
      organizationSlug,
      disbursementId,
      { format: 'json' },
    );

    const response = await axios.get(disbursementUrl);
    return new Promise((resolve) => {
      setDisbursement(response.data);
      resolve(response.data);
    });
  };

  const handleApproveAction = async () => {
    const entity = await fetchData();
    approveOrOpenModal(entity);
  };

  const approveAction = isApprovable ? (
    <TrueLinkButton
      className={{
        disabled: isSubmitting,
      }}
      disabled={isSubmitting}
      onClick={handleApproveAction}
      style={{ padding: '2px 10px 3px' }}
      variant="primary"
    >
      {isSubmitting ? 'Submitting...' : 'Approve'}
    </TrueLinkButton>
  ) : (
    <span>Waiting for final approval</span>
  );

  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {approveAction}
      {isApprovable && (
        <TrueLinkButton
          className={'btn btn-sm normal'}
          onClick={openDenyModal}
          style={{ padding: '2px 10px 3px' }}
          variant="error"
        >
          Deny
        </TrueLinkButton>
      )}
      <TrueLinkButton
        className="js-view-details"
        onClick={() => {
          row.toggleRowExpanded();
        }}
        size="small"
        style={{ width: '61px' }}
        variant="neutral"
      >
        {row.isExpanded ? 'Close' : 'View '}
      </TrueLinkButton>
      {modalOpen && (
        <PendingDisbursementAdminApprovalModal
          approveDisbursement={approveEntity}
          disbursement={disbursement}
          onClose={closeModal}
        />
      )}
    </Box>
  );
}
