export function getFraudBlockText(type) {
  // See also app/views/cards/_fraud_block_banner.html.haml
  switch (type) {
    case 'transaction_risk_support_required':
      return "We've frozen this account due to suspicious activity. Please review all recent transactions and order a replacement Visa card. Contact us immediately for help filing a dispute claim if necessary.";
    case 'transaction_risk_self_serve':
      return "We've frozen this account due to suspicious activity. Please review recent transactions. If all transactions are authorized, you can unfreeze the Visa card by clicking the Reactivate button on the account tab. If any transactions are unfamiliar or fraudulent, order a replacement card from the account tab and contact customer support immediately to open a dispute claim.";
    default:
      return "To protect this account, we've temporarily set the card inactive. This may be due to several failed PIN transactions or many transactions with the same merchant in a short period. Please review the recent transactions. You can reactivate the card if all transactions were authorized or order a replacement card from the Account tab.";
  }
}
