import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/TrueLinkTextInput';

export default function ApprovedDisbursementsFilters({
  globalFilter,
  setGlobalFilter,
  headerGroups,
  setAllFilters,
  filterTitle,
}) {
  const [value, setValue] = useState(globalFilter);
  const handleClear = () => {
    setValue('');
    setGlobalFilter('');
    setAllFilters([]);
  };
  const classes = makeStyles({
    searchInput: {
      height: '36px',
      marginBottom: 0,
      padding: 0,
    },
  });
  const onChange = useAsyncDebounce(setGlobalFilter, 200);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '15px',
          borderBottom: '1px solid rgb(189, 189, 189)',
        }}
      >
        <Typography style={{ fontSize: '24px', lineHeight: '31px' }} variant="body1">
          {filterTitle || 'Approved Disbursements'}
        </Typography>
        <TrueLinkTextInput
          className={classes.searchInput}
          inputProps={{ id: 'approved-disbursements-search' }}
          isFormik={false}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          value={value || ''}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {headerGroups?.map((headerGroup, i) => (
          <div
            key={i}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, idx) => (
              <div key={`col-${idx}`} {...column.getHeaderProps()}>
                {column.canFilter ? column.render('Filter', { open, setOpen }) : null}
              </div>
            ))}
          </div>
        ))}
        <div style={{ marginLeft: '15px' }}>
          <TrueLinkLink onClick={handleClear} size="small" variant="primary">
            Clear All
          </TrueLinkLink>
        </div>
      </div>
    </>
  );
}

ApprovedDisbursementsFilters.propTypes = {
  setGlobalFilter: PropTypes.func,
  globalFilter: PropTypes.string,
  setAllFilters: PropTypes.func,
  headerGroups: PropTypes.array,
  rangeFilterable: PropTypes.bool,
  filterTitle: PropTypes.string,
};
