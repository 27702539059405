/* eslint-disable import/order, import/first */
import 'jquery';
import 'jquery-ujs';
import 'jquery-validation';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
require('vendor/jquery.validate.methods.js');
require('./jquery_ui');
require('./bootstrap');
require('./datatables');

require('vendor/truelink');
require('vendor/custom_truelink_chosen.jquery');
require('vendor/select2');

require('single/additional_card_form');
require('single/account_activation_editor.js');
require('single/legal.js');
require('single/dashboard_home.js');
require('single/admin_interface.js');
require('single/signups.js');
require('single/base.js');
require('single/account_fee_settings.js');
require('single/utm_vars.js');

import { Notifier } from '@airbrake/browser';

import moment from 'moment-timezone'; // Customer service notes only
import Mousetrap from 'mousetrap'; // Accounts page only
import numeral from 'numeral'; // Cards page only
import React from 'react';
import ReactDOM from 'react-dom';
import ReactInputMask from 'react-input-mask'; // All over the place
// react-flux is... special and is pulled from a static file.
/* eslint-disable import/default, no-unused-vars */
import flux from './ReactFlux.js';
import I18n from 'i18n-js'; // All over the place
import * as RailsRoutes from 'member_routes'; // All over the place
/**
 * Export some webpacker react components as globals so react components still
 * in sprockets can find and use them as dependencies.
 *
 * Once all uses of a component listed here is in webpack we can remove this
 * global export and instead use an import in each place it is used.
 */
import {
  authenticationFilter,
  backtraceFilter,
  contextInfoFilter,
  extensionsFilter,
  scriptsFilter,
} from './Airbrake';
import DisplayModal from './DisplayModal'; // Organization page and more

window.React = React;
window.ReactDOM = ReactDOM;
window.RailsRoutes = RailsRoutes;

window.ReactInputMask = ReactInputMask;

window.moment = moment;

window.numeral = numeral;

window.Mousetrap = Mousetrap;

if (!['development', 'test'].includes(window.environment)) {
  let projectId;
  let projectKey;
  if (window.environment === 'production') {
    projectId = '239245';
    projectKey = 'aaedec4c766acec69c5faa6d95128669';
  } else {
    projectId = '339759';
    projectKey = '4a216cef126e967bacbbd98824beb1b7';
  }

  const airbrake = new Notifier({
    projectId,
    projectKey,
    keysBlocklist: [/ssn/, /password/, /social_security/, /account_number/],
  });

  airbrake.addFilter(contextInfoFilter);
  airbrake.addFilter(scriptsFilter);
  airbrake.addFilter(backtraceFilter);
  airbrake.addFilter(extensionsFilter);
  airbrake.addFilter(authenticationFilter);
}

/**
 * Export some webpacker react components as globals so react components still
 * in sprockets can find and use them as dependencies.
 *
 * Once all uses of a component listed here is in webpack we can remove this
 * global export and instead use an import in each place it is used.
 */
window.I18n = I18n;

/** @global */
window.flux = window.fluxReact;

window.displayModal = DisplayModal;
