import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import SpendingSettings, {
  BlockedTransactions,
  SpendingSettingsSnapshot,
} from '@truelinkfinancial/spending-settings-web-sdk';
import axios from 'axios';
/* eslint-disable import/namespace, import/default */
import pkceChallenge from 'pkce-challenge';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles({
  sdkContainer: {
    textAlign: 'start',
    '& input[type="text"]': {
      border: '0',
      boxShadow: 'none',
      padding: '8.5px 14px 8.5px 0',
      margin: '0',
      height: '40px',
    },

    '& h1': {
      display: 'none',
    },

    '& input[disabled]': {
      backgroundColor: '#fff',
    },

    '& .snapshot': {
      '& input[type="text"]': {
        padding: '8.5px 14px  !important',
      },
    },
  },
  '@global': {
    'input#merchant_limit[type="number"], input#merchant_name[type="text"]': {
      height: '40px !important',
      marginBottom: '0 !important',
    },
    'input#merchant-cluster[type="text"]': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
    },
    '#merchant_info': {
      boxShadow: 'none',
    },
  },
});

const HuntingtonTheme = {
  fontFamily: 'Mulish',
  fontSize: '16px',
  textColor: '#394048',
  bannerColor: '#394048',
  maxWidth: '1280px',
  typography: {
    fontFamily: 'Mulish',
    h1: {
      fontFamily: '"Huntington Apex", sans-serif',
      fontSize: '24px',
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#2D822A',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#1C2025',
    },
    h6: {
      fontSize: '24px',
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.3,
    },
    body2: {
      fontSize: '14px',
    },
  },
  palette: {
    primary: {
      main: '#2D822A',
    },
    secondary: {
      main: '#394048',
    },
    tertiary: {
      main: '#2D822A',
    },
    darkText: {
      main: '#394048',
    },
    banner: {
      main: '#EFF1F3',
      contrast: '#394048',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#EFF1F3',
            },
            '&.Mui-expanded': {
              backgroundColor: '#EFF1F3',
            },
          },
          '& .MuiAccordionDetails-root': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: '14px',
          borderRadius: '40px',
        },
        outlined: {
          border: '2px solid #394048',
          '&:hover': {
            border: '2px solid #2D822A',
            color: '#fff !important',
            background: '#2D822A',
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        },
        contained: {
          border: '2px solid #394048',
          '&:hover': {
            border: '2px solid #2D822A',
            color: '#fff',
            background: '#2D822A',
          },
        },
        iconSizeMedium: {
          display: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          display: 'block',
          fontWeight: '600',
          fontSize: '16px',
          color: '#394048',
          padding: '0 8px',
          background: 'white',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          backgroundColor: '#E7EAEB',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input[type=text]': {
            fontSize: 16,
          },
        },
      },
    },
  },
};

const visibilityDefaultConfig = {
  cashAccess: true,
  budgetSettings: false,
  curfewSettings: false,
  merchantSettings: true,
  spendingCategories: true,
  spendingLimits: true,
  temporaryOverrides: true,
};

const ableProgramDisabledSpendingSettings = [
  'GAMBLING',
  'MONEY_TRANSFERS',
  'INTERNATIONAL_TRANSACTIONS',
];

export default function SpendingSettingsSdkContainer({
  accountId,
  canEdit,
  showTestingToggles,
  timestamp,
  visibilityConfig = visibilityDefaultConfig, // Use default config if none provided
  showBlockedTransactions,
  showSpendingSettingsSnapshot,
}) {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [readOnly, setReadOnly] = useState(!canEdit);
  const [codeVerifier, setCodeVerifier] = useState(null);
  const [code, setCode] = useState(null);
  const [disabledSpendingSettings, setDisabledSpendingSettings] = useState([]);
  const [disableCashAccess, setDisableCashAccess] = useState(false);

  useEffect(() => {
    const url = RailsRoutes.api_v2_account_path({
      id: accountId,
      format: 'json',
    });

    const params = {
      'fields[account.analysis]': 'showBudgetSettings,showCurfewSettings',
      include: 'currentCard',
    };

    axios.get(url, { params }).then((response) => {
      const meta = response?.data?.data?.meta;
      const showBudget = meta.showBudgetSettings;
      visibilityConfig.budgetSettings = showBudget;
      const showCurfew = meta.showCurfewSettings;
      visibilityConfig.curfewSettings = showCurfew;

      const programName = response?.data?.data?.attributes?.cardProgram;
      // Disable 'GAMBLING', 'MONEY_TRANSFERS', and 'INTERNATIONAL_TRANSACTIONS' for able accounts
      if (programName === 'able') {
        setDisabledSpendingSettings(ableProgramDisabledSpendingSettings);
      }
      // Disable cash access for able and stable accounts
      if (programName === 'able' || programName === 'stable' || programName === 'stable_virtual') {
        setDisableCashAccess(true);
      }
    });

    const getAuthorizationCode = async () => {
      try {
        const { code_challenge: codeChallenge, code_verifier } = await pkceChallenge();
        setCodeVerifier(code_verifier);
        const response = await axios.post(
          RailsRoutes.api_v2_authorization_grants_path({ format: 'json' }),
          {
            data: {
              attributes: { accountId, codeChallenge, codeChallengeMethod: 'S256' },
            },
          },
        );
        setCode(response.data.data.attributes.code);
      } catch (error) {
        window.Truelink.flash('error', 'Could not get an authorization code.');
      }
    };
    getAuthorizationCode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleReadOnlyChange = (event) => {
    setReadOnly(event.target.checked);
  };

  const renderSpendingSettings = !!code && !!codeVerifier;

  return (
    <div className={classes.sdkContainer}>
      {showTestingToggles && (
        <>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 2 }}
          >
            <div>True Link Theme</div>
            <Switch
              checked={checked}
              inputProps={{ 'aria-label': 'Theme toggle' }}
              onChange={handleChange}
            />
            <div style={{ width: '140px' }}>Huntington Theme</div>
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 2 }}
          >
            <div>Admin View</div>
            <Switch
              checked={readOnly}
              inputProps={{ 'aria-label': 'View toggle' }}
              onChange={handleReadOnlyChange}
            />
            <div style={{ width: '140px' }}>Cardholder View</div>
          </Grid>
        </>
      )}
      {renderSpendingSettings && !showBlockedTransactions && !showSpendingSettingsSnapshot && (
        <SpendingSettings
          baseUrl={''}
          code={code}
          codeVerifier={codeVerifier}
          disableCashAccess={disableCashAccess}
          disabledSpendingSettings={disabledSpendingSettings}
          readOnly={readOnly}
          showAddMerchantButton={!checked}
          themeConfig={checked ? HuntingtonTheme : {}}
          visibilityConfig={visibilityConfig}
        />
      )}
      {renderSpendingSettings && showBlockedTransactions && !showSpendingSettingsSnapshot && (
        <BlockedTransactions
          baseUrl={''}
          code={code}
          codeVerifier={codeVerifier}
          readOnly={readOnly}
          themeConfig={checked ? HuntingtonTheme : {}}
          visibilityConfig={visibilityConfig}
        />
      )}
      {renderSpendingSettings && showSpendingSettingsSnapshot && !showBlockedTransactions && (
        <SpendingSettingsSnapshot
          baseUrl={''}
          code={code}
          codeVerifier={codeVerifier}
          themeConfig={checked ? HuntingtonTheme : {}}
          timestamp={timestamp}
          visibilityConfig={visibilityConfig}
        />
      )}
    </div>
  );
}

SpendingSettingsSdkContainer.propTypes = {
  visibilityConfig: PropTypes.object,
  accountId: PropTypes.string,
  canEdit: PropTypes.bool,
  showTestingToggles: PropTypes.bool,
  showBlockedTransactions: PropTypes.bool,
  showSpendingSettingsSnapshot: PropTypes.bool,
  timestamp: PropTypes.string,
};
