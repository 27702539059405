import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardUserStore from 'react/member/stores/DashboardUserStore';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function PendingDisbursementAdminApprovalModal({
  approveDisbursement,
  onClose,
  disbursement,
}) {
  const [authorizedUsers, setAuthorizedUsers] = useState([]);

  useEffect(() => {
    const onChangeAuthorizedUsers = (users) => {
      setAuthorizedUsers(users);
    };

    DashboardUserStore.on('authorizedUsers.fetch', onChangeAuthorizedUsers);

    const isRecurring = !!disbursement.isRecurring;
    DashboardActions.fetchAuthorizedUsers(disbursement.id, isRecurring);

    return () => {
      DashboardUserStore.off('authorizedUsers.fetch', onChangeAuthorizedUsers);
    };
  }, [disbursement]);

  const userSelect = (users) =>
    users.map((user, idx) => (
      <option key={idx} value={user.slug}>
        {user.full_name}
      </option>
    ));

  const renderForm = () => {
    const org = disbursement?.trust_beneficiary?.organization;
    const selfApprovalAllowed = org?.self_approval_allowed;
    const isSelfApprovalAllowed = selfApprovalAllowed ? 'can' : "can't";

    return (
      <form id="disbursement_approval_form">
        <p style={{ marginBottom: 20 }}>
          Only authorized users can approve this disbursement. <br />
          For this organizer, users {isSelfApprovalAllowed} approve their own disbursements.
        </p>
        <select id="requested_by_id">{userSelect(authorizedUsers)}</select>
      </form>
    );
  };

  const handleApproveDisbursement = useCallback(
    (ev) => {
      ev.preventDefault();
      const userId = document.getElementById('requested_by_id')?.value;
      approveDisbursement(userId);
    },
    [approveDisbursement],
  );

  const header = 'Who requested this approval?';
  const footer = (
    <TrueLinkButton
      className="btn btn-success"
      id="admin_disbursement_approval"
      onClick={handleApproveDisbursement}
      variant="none"
    >
      Approve
    </TrueLinkButton>
  );

  return (
    <PopUp footer={footer} header={header} maxWidth="500px" onClose={onClose} openModal>
      {renderForm()}
    </PopUp>
  );
}

PendingDisbursementAdminApprovalModal.propTypes = {
  approveDisbursement: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disbursement: PropTypes.object,
};
